import {CoursesRepository} from '../../infrastructure/data/repositories/courses.repository.impl';
import {Store} from 'vuex';
import {ICoursesRepository} from '@/core/domain/repositories/courses.repository';
import {Course, PaginatedCourses} from "@/core/domain/entities/course.entity";

export class CoursesUseCase {
    private _repo: ICoursesRepository;
    private _store: Store<unknown>;

    constructor(store: Store<unknown>) {
        this._repo = new CoursesRepository();
        this._store = store;
    }

    async getCourses(page: number, limit: number, language: string, category?: string): Promise<PaginatedCourses> {
        const data = await this._repo.getCourses(page, limit, language, category);
        await this._store.dispatch('setCourses', data);
        return data;
    }

    async getCourse(courseId: string, language: string): Promise<Course | undefined> {
        const data = await this._repo.getCourse(courseId, language);
        await this._store.dispatch('setCourse', data);
        return data;
    }

    async create(details: any, priceUSD: number, difficulty: string, categories: Array<string>): Promise<boolean> {
        return await this._repo.createCourse(details, priceUSD, difficulty, categories);
    }

    async addModule(title: string, description: string, duration: string, courseId: string): Promise<boolean> {
        return await this._repo.addModule(title, description, duration, courseId);
    }

    async addSection(title: string, description: string, duration: string, courseModuleId: string, file: File): Promise<boolean> {
        return await this._repo.addSection(title, description, duration, courseModuleId, file);
    }
}
