export default {
    data() {
        return {
           courseItems:  [
               {
                   id: 3,
                   courseImage: require(`@/assets/img/course/curso_defi.png`),
                   listImg:require(`@/assets/img/course/curso_defi.png`),
                   lesson:'35',
                   title: 'Bases Matemáticas dios Álgebra Ecuacion',
                   rating: '4.3',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-3.jpg`),
                   teacherName: 'Alan Luna',
                   category: 'Blockchain',
                   price:'13.00',
                   oldPrice:'19.00',
                   color:'green'
               },
               {
                   id: 4,
                   courseImage: require(`@/assets/img/course/curso_criptomonedas.png`),
                   listImg:require(`@/assets/img/course/curso_criptomonedas.png`),
                   lesson:'60',
                   title: 'Strategy law and organization Foundation',
                   rating: '3.5',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-4.jpg`),
                   teacherName: 'Elon Gated',
                   category: 'Criptomonedas',
                   price:'62.00',
                   oldPrice:'97.00',
                   color:'blue'
               },
               {
                   id: 6,
                   courseImage: require(`@/assets/img/course/curso_trading.png`),
                   listImg:require(`@/assets/img/course/curso_trading.png`),
                   lesson:'38',
                   title: 'Bases Matemáticas dios Álgebra Ecuacion',
                   rating: '4.8',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-6.jpg`),
                   teacherName: 'Brian Cumin',
                   category: 'Criptomonedas',
                   price:'35.00',
                   oldPrice:'46.00',
                   color:'pink'
               },

           ]
        }
    }
}