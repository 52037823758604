<template>
  <div>
    <Header headerShadow="header__area header__padding header__padding-2 header__shadow"/>
    <section class="signup__area po-rel-z1 pt-100 pb-145">
      <div class="sign__shape">
        <img class="man-1"
             src="https://bullfy-bucket.s3.us-east-1.amazonaws.com/COVER_COURSES/43f07f09-cab0-4050-9997-2048fb235124/7ca11a56-87d1-4ca7-85df-9f3060fdf46a?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA4MTWKDT3P62XLFGI%2F20240419%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240419T115109Z&X-Amz-Expires=3600&X-Amz-Signature=60fbe5801bc5618e577784a938306c87cf8dfa684ebeeb62984f118d64bcb5f6&X-Amz-SignedHeaders=host&x-id=GetObject"
             alt="">
        <img class="man-2" src="@/assets/img/icon/sign/man-2.png" alt="">
        <img class="circle" src="@/assets/img/icon/sign/circle.png" alt="">
        <img class="zigzag" src="@/assets/img/icon/sign/zigzag.png" alt="">
        <img class="dot" src="@/assets/img/icon/sign/dot.png" alt="">
        <img class="bg" src="@/assets/img/icon/sign/sign-up.png" alt="">
      </div>
      <div class="container mt-4">
        <div class="card mx-auto" style="
              max-width: 500px;
              background-color: white;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            ">
          <div class="card-body">
            <h2 class="card-title text-center">{{ $t('course.new') }}</h2>
            <form>
              <div class="form-group mt-4 mb-4">
                <label for="title" class="text-black">{{ $t('course.title') }}:</label>
                <input type="text" class="form-control bg-lightblue" id="title" v-model="form.title" required/>
              </div>
              <div class="form-group mt-4 mb-4">
                <label for="description" class="text-black">{{ $t('course.description') }}:</label>
                <textarea class="form-control bg-lightblue" id="descripcion" v-model="form.description" rows="3"
                          required></textarea>
              </div>
              <div class="form-group mt-4 mb-4">
                <label for="category" class="text-black">{{ $t('categories.name') }}:</label>
                <select class="form-control bg-lightblue" id="category" v-model="selectedCategory">
                  <option :selected="true" :value="{}">{{ $t('categories.select') }}</option>
                  <option v-for="category in categories" :value="category.name"
                          :key="category.categoryId">{{ category.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mt-4 mb-4">
                <label for="nivel" class="text-black">Nivel del Curso:</label>
                <select class="form-control bg-lightblue" id="nivel" v-model="form.difficulty" required>
                  <option value="" disabled selected>Seleccionar Nivel</option>
                  <option value="BEGINNER">Principiante</option>
                  <option value="INTERMEDIATE">Intermedio</option>
                  <option value="ADVANCED">Avanzado</option>
                </select>
              </div>
              <div class="form-group mt-4 mb-4">
                <label for="price" class="text-black">{{ $t('course.price') }}:</label>
                <input type="text" class="form-control bg-lightblue" id="titulo" v-model="form.price" required/>
              </div>

              <!--              <div class="form-group mt-4 mb-4">
                              <label for="dirigido" class="text-black">Dirigido a:</label>
                              <input type="text" class="form-control bg-lightblue" id="dirigido" v-model="dirigido" required />
                            </div>-->

              <!--              <div class="form-group mt-4 mb-4" v-for="(input, index) in aprenderInputs" :key="index">
                              <label class="text-black" v-if="index === 0">Que vas a aprender en este curso?:</label>
                              <label class="text-black" v-else>&nbsp;</label> &lt;!&ndash; Espacio en blanco para los inputs adicionales &ndash;&gt;
                              <div class="input-group">
                                <input type="text" class="form-control bg-lightblue" v-model="aprenderInputs[index]" required/>
                                <div class="input-group-append">
                                  <button @click="clearAprenderInput(index)" type="button" class="btn btn-danger">
                                    <i class="fas fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div class="text-center">
                              <button @click="addNewInput" type="button" class="btn btn-primary mb-2">
                                <i class="fas fa-plus"></i> Agregar Nuevo Campo
                              </button>
                            </div>-->

              <!--              <div class="custom-upload mt-4 mb-4">
                              <label for="media" class="custom-upload-label">
                                <i class="fas fa-cloud-upload-alt custom-upload-icon"></i>
                                <br/>
                                {{ uploadedFileName || 'Arrastra y suelta o selecciona un archivo (imagen/video)' }}
                              </label>
                              <input type="file" class="custom-upload-input" id="media" @change="handleMediaUpload" required/>
                            </div>-->
              <div class="text-center">
                <button type="button" @click="submitForm" class="btn btn-primary btn-block mt-4 btn-lg">
                  {{ $t('course.create') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Header from '@/presentation/components/Home/Header.vue';
import {defineComponent} from 'vue';
import {useToast} from "vue-toastification";
import {mapGetters, useStore} from "vuex";
import {key} from "@/infrastructure/di/store";
import {CoursesUseCase} from '@/core/usecases/courses.usecase';
import {CategoriesUseCase} from "@/core/usecases/categories.usecase";


export default defineComponent({
  name: 'NewCourse',
  components: {
    Header,
  },

  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        difficulty: '',
        price: 0
      },
      selectedCategory: '',
      file: null,
      fileName: '',
      toast: useToast()
    }
  },
  methods: {
    handleMediaUpload(event) {
      const file = event.target.files[0];
      this.file = file;
      this.fileName = file.name;
    },
    formIsValid() {
      return this.form.title && this.form.description
    },
    async submitForm() {
      const details = {
        name: this.form.title,
        title: this.form.title,
        subtitle: this.form.title,
        description: this.form.description,
        duration: '1',
      }
      const coursesUseCase = new CoursesUseCase(this.store);
      try {
        console.log('course case')
        const response = await coursesUseCase.create({'es': {...details}}, this.form.price, this.form.difficulty, [this.selectedCategory]);
        if(response){
          this.toast.success('Curso creado satisfactoriamente , agrega los contenidos');
          this.$router.push('/instructor-details');
        }else{
          this.toast.error('Falla al crear curso')
        }

      } catch (e: any) {
        console.error(e);
        const message = e?.data?.message ? e?.data?.message : 'Falla al crear curso';
        this.toast.error(message)
      }
    },
    async getCategories(language: string) {
      const categoriesUseCase = new CategoriesUseCase(this.store);
      await categoriesUseCase.getCategories(language);
    }
  },

  computed: {
    ...mapGetters(['categories']),
  },
  mounted() {
    this.getCategories(this.$i18n.locale);
  }
});
</script>

<style scoped>
.bg-lightblue {
  background-color: #e9ecef;
}

.text-black {
  color: #000;
}


</style>
