import {HttpBase} from '../../di/httpBase';
import {Categories} from '@/core/domain/entities/category.entity';
import {ICategoryDatasource} from '@/core/domain/datasources/category.datasource';

class CategoriesDatasource implements ICategoryDatasource {
  private _httpBase: HttpBase;

  constructor() {
    this._httpBase = new HttpBase();
  }

  async getCategories(language = 'en'): Promise<Categories> {
      return (await  this._httpBase.get('category', {language})).data.data;
  }
}

export {
  CategoriesDatasource
}
