<template>
  <section class="course__area pt-115 pb-120 grey-bg">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-xxl-5 col-xl-6 col-lg-6">
          <div class="section__title-wrapper mb-60">
            <h2 class="section__title">Los mejores<span class="yellow-bg yellow-bg-big"> Cursos<img
                src="@/assets/img/shape/yellow-bg.png" alt=""></span> para ti</h2>
            <p>Aquí encontraras los mejores cursos disponibles para tí.</p>
          </div>
        </div>
        <div class="col-xxl-7 col-xl-6 col-lg-6">
          <div class="course__menu d-flex justify-content-lg-end mb-60">
            <div class="masonary-menu filter-button-group">
              <nav>
                <div className="nav nav-tabs justify-content-center" id="portfolio-tab" role="tablist">
                  <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all"
                          type="button" role="tab" aria-controls="nav-all" aria-selected="true">{{$t('actions.seeAll')}} <span
                      class="tag">{{$t('tooltip.new', 2)}}</span></button>

                  <button v-for="(_,index) in (new Array(4))" :key="index" className="nav-link" id="nav-trending-tab" data-bs-toggle="tab" data-bs-target="#nav-trending"
                          type="button" role="tab" aria-controls="nav-trending" aria-selected="false">{{categories[index]?.name}}
                  </button>

<!--                  <button className="nav-link" id="nav-popularity-tab" data-bs-toggle="tab"-->
<!--                          data-bs-target="#nav-popularity" type="button" role="tab" aria-controls="nav-popularity"-->
<!--                          aria-selected="false">Apps-->
<!--                  </button>-->

<!--                  <button className="nav-link" id="nav-featured-tab" data-bs-toggle="tab" data-bs-target="#nav-featured"-->
<!--                          type="button" role="tab" aria-controls="nav-featured" aria-selected="false">Cripto Cursos-->
<!--                  </button>-->

<!--                  <button className="nav-link" id="nav-art-design-tab" data-bs-toggle="tab"-->
<!--                          data-bs-target="#nav-art-design" type="button" role="tab" aria-controls="nav-art-design"-->
<!--                          aria-selected="false">Desarrollo De juegos-->
<!--                  </button>-->
                </div>
              </nav>

            </div>
          </div>
        </div>
      </div>
      <div class="row grid tab-content">

        <GridTabItems :courses="courses.data"/>
        <!--                   <div className="tab-pane fade" id="nav-trending" role="tabpanel" aria-labelledby="nav-trending">-->
        <!--                      <CourseItems :itemNumberStart='1' :itemNumberEnd="5" />-->
        <!--                   </div>-->

        <!--                    <div className="tab-pane fade" id="nav-popularity" role="tabpanel" aria-labelledby="nav-popularity">-->
        <!--                       <CourseItems :itemNumberStart='2' :itemNumberEnd="5" />-->
        <!--                   </div>-->

        <!--                    <div className="tab-pane fade" id="nav-featured" role="tabpanel" aria-labelledby="nav-featured">-->
        <!--                       <CourseItems :itemNumberStart='4' :itemNumberEnd="6" />-->
        <!--                   </div>-->

        <!--                   <div className="tab-pane fade" id="nav-art-design" role="tabpanel" aria-labelledby="nav-art-design">-->
        <!--                     <CourseItems :itemNumberStart='3' :itemNumberEnd="6" />-->
        <!--                  </div>-->

      </div>
    </div>
  </section>
</template>

<script lang="ts">
import GridTabItems from '@/presentation/components/Courses/GridTabItems.vue';
import {CoursesUseCase} from '@/core/usecases/courses.usecase';
import {mapGetters, useStore} from 'vuex';
import {CategoriesUseCase} from '@/core/usecases/categories.usecase';
import {defineComponent} from 'vue';
import {key} from '@/infrastructure/di/store';

export default defineComponent({
  name: 'homeCourse',
  components: {GridTabItems},
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  methods: {
    async getCourses(page: number, language: string, category ?: string) {
      const coursesUseCase = new CoursesUseCase(this.store);
      await coursesUseCase.getCourses(page, 20, language, category);
    },
    async getCategories(language: string) {
      const categoriesUseCase = new CategoriesUseCase(this.store);
      await categoriesUseCase.getCategories(language);
    },
  },
  computed: {
    ...mapGetters(['courses']),
    ...mapGetters(['categories'])

  },
  mounted() {
    this.getCourses(1, this.$i18n.locale);
    this.getCategories(this.$i18n.locale);
  }
});
</script>
