<template>
<header>
   <div id="header-sticky" :class="`${isSticky ? 'header__area header__transparent header__padding-2 sticky' : `${headerShadow ? headerShadow : 'header__area header__transparent header__padding-2'}`}`">
      <div class="container">
         <div class="row align-items-center">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
               <div class="header__left d-flex">
                  <div class="logo">
                     <router-link to="/">
                        <img src="@/assets/img/logo/bullfy.png" alt="logo">
                     </router-link>
                  </div>
                  <div class="header__category d-none d-lg-block">
                  </div>
               </div>
            </div>
            <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
               <div class="header__right d-flex justify-content-end align-items-center">
                  <div class="main-menu main-menu-2">
                     <nav id="mobile-menu" class="d-none d-xl-block">
                        <ul>
                           <li>
                              <router-link to="/">{{ $t('menu.home') }}</router-link>
                           </li>
                           <li class="has-dropdown">
                            <router-link to="/courses">{{ $t('header.courses') }}</router-link>
                              <ul class="submenu">
                                 <li>
                                    <router-link to="/courses">{{ $t('header.courses') }}</router-link>
                                 </li>
                                 <li>
                                    <router-link to="/courses-sidebar">{{ $t('header.coursesList') }}
                                    </router-link>
                                 </li>
                                 <li>
                                    <router-link to="/course-details">{{ $t('header.courseDetails') }}
                                    </router-link>
                                 </li>
                              </ul>
                           </li>
                        

                        </ul>
                     </nav>
                  </div>
                  <div class="header__btn header__btn-2 ml-50 d-none d-sm-block">
                     <router-link to="/register" class="e-btn">{{ $t('header.signUp') }}</router-link>
                  </div>
                  <div class="sidebar__menu d-xl-none">
                     <div @click="handleSidebar" class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                           <span class="line"></span>
                           <span class="line"></span>
                           <span class="line"></span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</header>

<!-- sidebar area start -->

  <!-- sidebar area end -->
</template>

<script>
export default {
   name:'HomeTwoHeader',
   data () {
    return {
      isSticky:false,
      showSidebar:false,
      menuOption: {
      menuShow: false,
      menuSearch: false,
      homeDropdown: false,
      coursesDropdown: false,
      pagesDropDown: false,
      blogDropdown: false,
      aboutDropdown: false,
   },
    }
  },
  props:{
    headerShadow:String,
  },
  methods:{
      handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       
       handleSidebar () {
         this.showSidebar = true;
       },
       handleSidebarClose () {
         this.showSidebar = false;
       },
  },
  mounted() {
      window.addEventListener('scroll',this.handleSticky)
   },
};
</script>
