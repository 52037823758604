import {ILecturesRepository} from '@/core/domain/repositories/lectures.repository';
import {Store} from 'vuex';
import {LecturesRepository} from '@/infrastructure/data/repositories/lectures.repository.impl';
import {LectureInputModel} from '@/infrastructure/data/models/lectureInput.model';

export class LecturesUseCase {
  private _repo: ILecturesRepository;
  private _store: Store<unknown>;

  constructor(store: Store<unknown>) {
    this._repo = new LecturesRepository();
    this._store = store;
  }

  async getLectures(courseId: string): Promise<void> {
    const data = await this._repo.getLectures(courseId);
    await this._store.dispatch('setLectures', data);
  }

  async getLecture(lectureId: string): Promise<void> {
    const data = await this._repo.getLecture(lectureId);
    await this._store.dispatch('setLecture', data);
  }

  async createLecture(body: LectureInputModel): Promise<boolean> {
    return await this._repo.createLecture(body);
  }

  async editLecture(lectureId: string, body: LectureInputModel): Promise<boolean> {
    return await this._repo.editLecture(lectureId, body);
  }

  async deleteLecture(lectureId: string): Promise<boolean> {
    return await this._repo.deleteLecture(lectureId);
  }
}
