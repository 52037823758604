import {Lecture} from '@/core/domain/entities/lecture.entity';
import {Modules} from '@/core/domain/entities/module.entity';

type LecturesState = {
  modules: Modules,
  lecture: Lecture,
}

const state: LecturesState = {
  modules: [],
  lecture: {} as Lecture
}

const getters = {
  lectures: (state: LecturesState) => state.modules,
  lecture: (state: LecturesState) => state.lecture
}

const mutations = {
  setLectures(state: LecturesState, modules: Modules) {
    state.modules = modules;
  },
  setLecture(state: LecturesState, lecture: Lecture) {
    state.lecture = lecture;
  }
}

const actions = {
  setLectures({commit}, payload: Modules) {
    commit('setLectures', payload);
  },
  setLecture({commit}, payload: Lecture) {
    commit('setLecture', payload);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
