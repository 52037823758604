<template>
  <div v-for="(course, index) in courses" :key="course.courseId" class="col-xxl-12">
    <div class="course__item white-bg mb-30 fix">
      <div class="row gx-0">
        <div class="col-xxl-4 col-xl-4 col-lg-4">
          <div class="course__thumb course__thumb-list w-img p-relative fix">
            <router-link :to="`/course-details/${course.courseId}`">
              <img :src="course?.thumbnail ?? courseItems[index]?.listImg" alt="">
            </router-link>
            <div class="course__tag">
              <!--              <a href="#" :class="course.color">{{ course.category }}</a>-->
              <a href="#">{{ course.detail?.category ?? courseItems[index]?.category }}</a>
            </div>
          </div>
        </div>
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="course__right">
            <div class="pb-60 course__content course__content-3">
              <div class="course__meta d-flex align-items-center">
                <div class="course__lesson mr-20">
                  <span><i class="far fa-book-alt"></i>{{ course.detail?.lessons ?? courseItems[index]?.lesson }} Lesson</span>
                </div>
                <div class="course__rating">
                  <span><i class="icon_star"></i>{{ course.rating }} ({{ course.totalRates }})</span>
                </div>
              </div>
              <h3 class="course__title course__title-3">
                <router-link :to="`/course-details/${course.courseId}`">{{ course.detail?.title }}</router-link>
              </h3>
              <div class="course__summary">
                <span v-if="!readMoreActivated[index]">{{ course.detail?.description.slice(0, 200) }}</span>
                <span class="link-primary c-pointer" v-if="!readMoreActivated[index] && course.detail?.description.length > 200" @click.self="()=> activateReadMore(index)">
                  Read more...
                </span>
                <span v-if="readMoreActivated[index]">{{ course.detail?.description }}</span>
                <span class="link-primary c-pointer" v-if="readMoreActivated[index]" @click.self="()=> activateReadMore(index)">
                  Read less...
                </span>
              </div>
              <div class="course__teacher d-flex align-items-center">
                <div class="course__teacher-thumb mr-15">
                  <img :src="course?.teacher?.thumbnail ?? courseItems[index]?.teacherImg " alt="">
                </div>
                <h6>
                  <router-link to="/instructor-details">{{
                      course?.teacher?.name ?? courseItems[index]?.teacherName
                    }}
                  </router-link>
                </h6>
              </div>
            </div>
            <div class="course__more course__more-2 d-flex justify-content-between align-items-center">
              <div class="course__status d-flex align-items-center">
                <!--                <span :class="course.color">${{ course.price }}</span>-->
                <span>{{ course.isFree ? 'Free' : `$${course.priceUSD}` }}</span>
                <!--                <span class="old-price">${{ course.oldPrice }}</span>-->
              </div>
              <div class="course__btn">
                <router-link :to="`/course-details/${course.courseId}`" class="link-btn">
                  Know Details
                  <i class="far fa-arrow-right"></i>
                  <i class="far fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CourseMixin from '@/mixins/courseItemsMixin';
import {defineComponent, PropType} from 'vue';
import {Courses} from '@/core/domain/entities/course.entity';

export default defineComponent({
  name: 'ListTabItems',
  mixins: [CourseMixin],
  props: {
    courses: {
      type: Object as PropType<Courses>
    }
  },
  data() {
    return {
      readMoreActivated: Array(this.$props.courses?.length).fill(false)
    }
  },
  methods: {
    activateReadMore(index: number) {
      this.readMoreActivated[index] = !this.readMoreActivated[index];
    },
  }
});
</script>

<style scoped lang="scss">
.course__content.pb-60 {
  padding-bottom: 60px !important;
}
.c-pointer {
  cursor: pointer;
}
</style>
