import {User} from '@/core/domain/entities/user.entity';

type UserState = {
  user: User
}

const state: UserState = {
  user: {} as User
}

const getters = {
  user: (state: UserState) => state.user
}

const mutations = {
  setUser(state: UserState, value: User) {
    state.user = value;
  }
}

const actions = {
  setUser({commit}, value: User) {
    commit('setUser', value);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
