<template>
  <div class="row">
    <div class="col-xxl-12">
      <div class="basic-pagination mt-30">
        <ul class="d-flex align-items-center">
          <li class="prev" :class="{'d-none': currentPage === 1}" @click="pageChange(currentPage - 1)">
            <div class="link-btn link-prev cursor-pointer">
              Prev
              <i class="arrow_left"></i>
              <i class="arrow_left"></i>
            </div>
          </li>
          <li class="pagination-slot" :class="{active: slot1(currentPage).active}">
            <div>
              <span>{{ slot1(currentPage).value }}</span>
            </div>
          </li>
          <li class="pagination-slot" :class="{active: slot2(currentPage, totalPages).active, 'd-none': totalPages < 2}">
            <div>
              <span>{{ slot2(currentPage, totalPages).value }}</span>
            </div>
          </li>
          <li class="pagination-slot" :class="{active: slot3(currentPage, totalPages).active, 'd-none': (totalPages < 3 || currentPage === totalPages)}">
            <div>
              <span>{{ slot3(currentPage, totalPages).value }}</span>
            </div>
          </li>
          <li class="next cursor-pointer" :class="{'v-none': currentPage === totalPages}" @click="pageChange(currentPage + 1)">
            <div class="link-btn">
              Next
              <i class="arrow_right"></i>
              <i class="arrow_right"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  name: 'PaginationArea',
  props: {
    currentPage: {
      type: Object as PropType<number>,
      required: true
    },
    totalPages: {
      type: Object as PropType<number>,
      required: true
    },
  },
  emits: ['update:page'],
  methods: {
    pageChange(page: number) {
      this.$emit('update:page', page);
    },
    slot1: (currentPage: number) => {
      const value = currentPage === 1 ? 1 : currentPage - 1;
      return {
        active: currentPage === value,
        value
      }
    },
    slot2: (currentPage: number, totalPages: number) => {
      const value = (currentPage === 1 && totalPages > 1) ? currentPage + 1 : currentPage;
      return {
        active: currentPage === value,
        value
      }
    },
    slot3: (currentPage: number, totalPages: number) => {
      const value = currentPage === totalPages ?  currentPage : currentPage === 1 ? currentPage + 2 : currentPage + 1;
      return {
        active: currentPage === value,
        value
      }
    },

  }
})
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.pagination-slot {
  cursor: pointer;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border: 2px solid #EEF0F6;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 8px;

  &.active {
    border: 2px solid #2B4EFF;
    background-color: #2B4EFF;
    color: white;
  }
}
.d-none {
  display: none;
}
.v-none {
  visibility: hidden;
}
</style>
