import {ILecturesRepository} from '@/core/domain/repositories/lectures.repository';
import {Modules} from '@/core/domain/entities/module.entity';
import {Lecture} from '@/core/domain/entities/lecture.entity';
import {LectureInputModel} from '@/infrastructure/data/models/lectureInput.model';
import {ILecturesDataSource} from '@/core/domain/datasources/lectures.datasource';
import {LecturesDatasource} from '@/infrastructure/data/datasources/lectures.datasource.impl';

export class LecturesRepository implements ILecturesRepository {
  private _dataSource: ILecturesDataSource;

  constructor() {
    this._dataSource = new LecturesDatasource();
  }

  async getLectures(courseId: string): Promise<Modules> {
    return await this._dataSource.getLectures(courseId);
  }

  async getLecture(lectureId: string): Promise<Lecture> {
    return await this._dataSource.getLecture(lectureId);
  }

  async createLecture(body: LectureInputModel): Promise<boolean> {
    return await this._dataSource.createLecture(body);
  }

  async editLecture(lectureId: string, body: LectureInputModel): Promise<boolean> {
    return await this._dataSource.editLecture(lectureId, body);
  }

  async deleteLecture(lectureId: string): Promise<boolean> {
    return await this._dataSource.deleteLecture(lectureId);
  }
}
