<template>
  <section class="signup__area po-rel-z1 pt-100 pb-145">
    <div class="sign__shape">
      <img class="man-1" src="@/assets/img/icon/sign/man-3.png" alt="">
      <img class="man-2 man-22" src="@/assets/img/icon/sign/man-22.png" alt="">
      <img class="circle" src="@/assets/img/icon/sign/circle.png" alt="">
      <img class="zigzag" src="@/assets/img/icon/sign/zigzag.png" alt="">
      <img class="dot" src="@/assets/img/icon/sign/dot.png" alt="">
      <img class="bg" src="@/assets/img/icon/sign/sign-up.png" alt="">
      <img class="flower" src="@/assets/img/icon/sign/flower.png" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
          <div class="section__title-wrapper text-center mb-55">
            <h2 class="section__title">{{ $t('signUp.createAccount') }} <br> {{ $t('signUp.new') }}</h2>
            <p>{{ $t('signUp.findMessage') }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
          <div class="sign__wrapper white-bg">
            <!-- <div class="sign__header mb-35">
               <div class="sign__in text-center">
                  <a href="#" class="sign__social g-plus text-start mb-15"><i class="fab fa-google-plus-g"></i>Ingresa con tu cuenta de google</a>
                  <p> <span>........</span> O,
                  <router-link to="/register">inicia con</router-link>
                  tu cuenta de Google<span> ........</span> </p>
               </div>
            </div> -->
            <div class="sign__form">
              <div class="sign__input-wrapper mb-25">
                <h5>{{ $t('signUp.name') }}</h5>
                <div class="sign__input">
                  <input type="text" v-model="fullName" :placeholder="$t('signUp.yourName')" required>
                  <i class="fal fa-user"></i>
                </div>
              </div>
              <div class="sign__input-wrapper mb-25">
                <h5>{{ $t('signUp.email') }}</h5>
                <div class="sign__input">
                  <input type="text" v-model="email" :placeholder="$t('signUp.yourEmail')" required>
                  <i class="fal fa-envelope"></i>
                </div>
              </div>
              <div class="sign__input-wrapper mb-25">
                <h5>{{ $t('signUp.password') }}</h5>
                <div class="sign__input">
                  <input type="password" v-model="password" :placeholder="$t('signUp.yourPassword')" required>
                  <i class="fal fa-lock"></i>
                </div>
              </div>
              <div class="sign__input-wrapper mb-10">
                <h5>{{ $t('signUp.repeatPassword') }}</h5>
                <div class="sign__input">
                  <input type="password" v-model="repeatPassword" :placeholder="$t('signUp.yourPassword')" required>
                  <i class="fal fa-lock"></i>
                </div>
              </div>
              <div class="sign__action d-flex justify-content-between mb-30">
                <div class="sign__agree d-flex align-items-center">
                  <input class="m-check-input" type="checkbox" id="m-agree" v-model="termsAccepted" require>
                  <label class="m-check-label" for="m-agree">{{ $t('signUp.accept') }}
                    <a
                        href="#">{{ $t('signUp.terms') }} </a>
                  </label>
                </div>
              </div>
              <button :disabled="!fullName || !email || !password || !repeatPassword" class="e-btn w-100"
                      type="button"
                      @click="register">
                {{ $t('signUp.register') }}
              </button>
              <div class="sign__new text-center mt-20">
                <p>{{ $t('signUp.alreadyRegistered') }}
                  <a href="https://consumer.bullfy.com/">{{ $t('signUp.signIn') }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {AuthUseCase} from "@/core/usecases/auth.usecase";
import {useToast} from "vue-toastification";
import {useStore} from 'vuex';
import {key} from '@/infrastructure/di/store';

export default {
  name: "SignUpArea",
  data() {
    return {
      email: '',
      fullName: '',
      password: null,
      repeatPassword: '',
      termsAccepted: false,
      toast: useToast()
    };
  },
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  methods: {
    async register() {
      if (!this.validateEmail(this.email)) {
        this.toast.warning(this.$t('signUp.notValidEmail'));
      } else if (this.password !== this.repeatPassword) {
        this.toast.warning(this.$t('signUp.notMatch'));
      } else if (!this.validatePassword(this.password)) {
        this.toast.warning(this.$t('signUp.notValidPassword'));
      } else if (!this.termsAccepted) {
        this.toast.warning(this.$t('signUp.acceptTerms'));
      } else {
        const useCase = new AuthUseCase(this.store);
        try {
          const res = await useCase.register(this.email, this.password, this.fullName);
          if (res) {
            this.toast.success(this.$t('signUp.success'));
            await this.$router.push('/');
          }
        } catch (error) {
          console.log(error)
          this.toast.error(this.$t('signUp.error'));
        }
      }
    },
    validatePassword(password) {
      const regex = /^(?=.*\d)(?=.*[a-z])\S{4,}$/;
      return regex.test(password);
    },
    validateEmail(email) {
      // Función para validar el formato de correo electrónico
      const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      return regex.test(email);
    }
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 5px;
}
</style>
