import {Categories} from '@/core/domain/entities/category.entity';

type CategoriesState = {
  categories: Categories
}

const state: CategoriesState = {
  categories: []
}

const getters = {
  categories: (state: CategoriesState)  => state.categories
}

const mutations = {
  setCategories(state: CategoriesState, categories: Categories) {
    state.categories = categories;
  }
}

const actions = {
  setCategories({commit}, data: Categories){
    commit('setCategories', data);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
