<template>
  <section class="teacher__area mt-60">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-6 offset-xxl-3">
          <div class="section__title-wrapper text-center mb-60">
            <h2 class="section__title">Únete a nuestra comunidad <br>
              Y se un <span class="yellow-bg">  Bullfier!!! <img src="@/assets/img/shape/yellow-bg-2.png"
                                                                 alt="">  </span> <br>
            </h2>
            <p>No tienes que emprender solo.</p>
          </div>
        </div>
      </div>
      <div class="carousel-container blue-bg-4">
        <div class="carousel " ref="carousel">
          <div v-for="teacher in TeachersData" :key="teacher.id"
               class="carousel-item">
            <div class="teacher__item text-center grey-bg-5 transition-3 mb-30">
              <div class="teacher__thumb w-img fix">
                <a href="#">
                  <img :src="teacher.img" alt="">
                </a>
              </div>
              <div class="teacher__content">
                <h3 class="teacher__title">
                  <router-link to="/instructor-details">{{ teacher.name }}</router-link>
                </h3>
                <span> {{ teacher.title }}</span>

                <div class="teacher__social">
                  <ul>
                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-12">
          <div class="teacher__more text-center mt-30">
            <router-link to="/instructor-register" class="e-btn e-btn-border e-btn-5">Conviertete en Instructor
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TeachersArea',
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      TeachersData: [
        {
          id: 1,
          img: require(`@/assets/img/teacher/carlota.png`),
          name: 'Carlota Sabina, ',
          title: 'Enterperneur',
        },
        {
          id: 2,
          img: require(`@/assets/img/teacher/javi.png`),
          name: 'JaviZone, ',
          title: 'Enterperneur',
        },
        {
          id: 3,
          img: require(`@/assets/img/teacher/emile.png`),
          name: 'EmileTrader,',
          title: 'Marketer',
        },
        {
          id: 4,
          img: require(`@/assets/img/teacher/roberto.png`),
          name: 'Roberto Sanz',
          title: 'Enterperneur',
        },
        {
          id: 5,
          img: require(`@/assets/img/teacher/gpstrader.png`),
          name: 'GpsOTrader',
          title: 'Enterperneur',
        },
        {
          id: 6,
          img: require(`@/assets/img/teacher/alberto.png`),
          name: 'Alberto Aneiros',
          title: 'Enterperneur',
        },
        {
          id: 8,
          img: require(`@/assets/img/teacher/bruno.png`),
          name: 'Bruno SanMartin',
          title: 'Enterperneur',
        },
        {
          id: 9,
          img: require(`@/assets/img/teacher/alan.png`),
          name: 'Alan Luna',
          title: '',
        },
        {
          id: 10,
          img: require(`@/assets/img/teacher/alvaro.png`),
          name: 'Alvaro Barroso',
          title: 'Enterperneur',
        },
        {
          id: 13,
          img: require(`@/assets/img/teacher/pako.png`),
          name: 'Pako',
          title: 'Enterperneur',
        },
        {
          id: 14,
          img: require(`@/assets/img/teacher/emidelasierra.png`),
          name: 'De la Sierra',
          title: 'Enterperneur',
        }
      ]
    }
  },
  methods: {
    handleMouseDown(e) {
      this.isDown = true;
      this.startX = e.pageX - this.$refs.carousel.offsetLeft;
      this.scrollLeft = this.$refs.carousel.scrollLeft;
    },
    handleMouseLeave() {
      this.isDown = false;
    },
    handleMouseUp() {
      this.isDown = false;
    },
    handleMouseMove(e) {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.carousel.offsetLeft;
      const walk = (x - this.startX) * 3; // Velocidad de desplazamiento
      this.$refs.carousel.scrollLeft = this.scrollLeft - walk;
    },
    handleTouchStart(e) {
      this.isDown = true;
      this.startX = e.touches[0].pageX - this.$refs.carousel.offsetLeft;
      this.scrollLeft = this.$refs.carousel.scrollLeft;
    },
    handleTouchEnd() {
      this.isDown = false;
    },
    handleTouchMove(e) {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.touches[0].pageX - this.$refs.carousel.offsetLeft;
      const walk = (x - this.startX) * 3; // Velocidad de desplazamiento
      this.$refs.carousel.scrollLeft = this.scrollLeft - walk;
    },
  },
  mounted() {
    console.log('mounted');
    this.$nextTick(() => {
      const carousel = this.$refs.carousel;
      if (carousel) {
        carousel.addEventListener('mousedown', this.handleMouseDown);
        carousel.addEventListener('mouseleave', this.handleMouseLeave);
        carousel.addEventListener('mouseup', this.handleMouseUp);
        carousel.addEventListener('mousemove', this.handleMouseMove);

        // Eventos táctiles
        carousel.addEventListener('touchstart', this.handleTouchStart);
        carousel.addEventListener('touchend', this.handleTouchEnd);
        carousel.addEventListener('touchmove', this.handleTouchMove);
      }
    });
  },
  beforeUnmount() {
    const carousel = this.$refs.carousel;
    if (carousel) {
      carousel.removeEventListener('mousedown', this.handleMouseDown);
      carousel.removeEventListener('mouseleave', this.handleMouseLeave);
      carousel.removeEventListener('mouseup', this.handleMouseUp);
      carousel.removeEventListener('mousemove', this.handleMouseMove);

      // Eventos táctiles
      carousel.removeEventListener('touchstart', this.handleTouchStart);
      carousel.removeEventListener('touchend', this.handleTouchEnd);
      carousel.removeEventListener('touchmove', this.handleTouchMove);
    }
  }
};
</script>

<style>
.carousel-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: auto;
  min-height: 21rem;
  box-sizing: border-box;
  cursor: grab;
}

.carousel {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 2.4rem 1rem;
  width: 100%;
  /*transition: transform 0.5s;
  animation: scroll 3s linear infinite;*/
}

.carousel-item {
  width: 23.33333333%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 0 0 auto; /* Evita que los items se ajusten dentro del flex container */
  margin-right: 10px; /* Espacio entre items */
  height: auto;
}

.teacher__item {
  width: 100%; /* Ajusta el ancho de cada item */
  margin-right: 10px; /* Espacio entre items */
}
@media screen and (max-width: 450px) {
  .carousel-container {
    overflow: hidden;
    margin:0;
    min-height: 12rem;
  }

  .carousel {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 2rem 0.3rem;
    width: 100%;
  }

  .carousel-item {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-right: 5px; /* Espacio entre items */
    height: auto;
  }

  .teacher__item {

    .teacher__thumb{

    }
  }
}


@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

</style>
