import axios, {AxiosError, AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig} from 'axios';
import {AuthUseCase} from "@/core/usecases/auth.usecase";
import {StorageConstants} from "@/core/domain/constants/storage.constants";

class HttpBase {
    private _instance: AxiosInstance;

    constructor() {
        this._instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL, headers: {
                // "Access-Control-Allow-Origin": "http://localhost:8080/",
                // 'Access-Control-Request-Headers': 'Content-Type, Authorization'
            }
        });
        this._instance.interceptors.request.use(value => {
            const token = localStorage.getItem(StorageConstants.TOKEN);
            const ignorePaths = ['auth/login']
            const headers: AxiosRequestHeaders = (token && !ignorePaths.some(e => value?.url?.match(e))) ? ({
                ...value.headers,
                Authorization: `Bearer ${token}`
            } as AxiosRequestHeaders) : value.headers;
            const config: InternalAxiosRequestConfig = {
                ...value,
                headers
            }
            return config;
        });
        this._instance.interceptors.response.use(res => res, async (err: AxiosError) => {
            const {response, config,} = err;
            const refreshToken = localStorage.getItem(StorageConstants.REFRESH_TOKEN);
            if (response && config && response.status === 401 && config.headers.has('Authorization')) {
                const {data} = await this.post('/auth/refresh-token', {refreshToken});
                AuthUseCase.saveAuthInfo(data.accessToken, data.refreshToken);
                const newConfig = {...config, headers: {...config.headers, Authorization: `Bearer ${data.authToken}`}};
                return await this._instance.request(newConfig)
            } else if (response && response.status === 400) {
                throw response;
            }
            return response;
        });
    }

    async get(path: string, queryParams?: any, headers?: any): Promise<any> {
        return await this._instance.get(path, {headers, params: queryParams});
    }

    async post(path: string, body: any, headers?: any): Promise<any> {
        return await this._instance.post(path, body, {headers});
    }

    async put(path: string, body: any, headers?: any): Promise<any> {
        return await this._instance.put(path, body, {headers});
    }

    async delete(path: string, headers?: any): Promise<any> {
        return await this._instance.delete(path, {headers});
    }

}

export {
    HttpBase
}
