<template>
  <section class="slider__area p-relative mt-60">
    <!--    <swiper :slides-per-view="1" effect="fade" :pagination="paginationOpts" :modules="swiperModules" :on-click="goToRegister"
                :autoplay="autoPlayOpts" :loop="true">-->

    <div class="slider__wrapper swiper-container">
      <swiper ref="mySwiper" :modules="swiperModules" :effect="'fade'"
              :loop="true" :autoplay="{delay: 5000}">
        <swiper-slide v-for="(course, index) in coursesToShow" :key="index">
          <div class="hero__content content"
               :style="{background: `linear-gradient(to right, ${course.backgroundFrom}, ${course.backgroundTo})`}">
            <div class="content__info">
              <div class="info__title" :style="{color: `${course.textColor}`}">
                <span>{{ course.title }}</span>
              </div>
              <div class="info__description">
                <span class="desc__text" :style="{color: `${course.textColor}`}">{{ course.body }}</span>
              </div>
              <div class="info__actions">
                <button @click="goToRegister" class="acts__start"
                        :style="{background: `${course.buttonColor}`, color: `${course.textButtonColor}`}">
                  {{ $t('home.stCourse') }}
                </button>
                <button class="acts__all" @click="goToCourses"
                        :style="{background: `transparent`, color: `${course.textColor}`, border:`1px solid ${course.textColor}` }">
                  {{ $t('actions.seeAll') }}
                </button>
              </div>
            </div>
            <div class="content__image" @click="goToRegister">
              <img :src="course.image" alt="">
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<style scoped lang="scss">

.swiper {
  height: 100%;
  --swiper-pagination-color: #ffffff;
  --swiper-pagination-bullet-size: 10px;
}

.hero {
  min-height: 400px;
  max-height: 500px;
  margin-top: 100px;
  margin-bottom: 50px;
  background: transparent;

  &__content.content {
    display: grid;
    grid-template: "info img" 1fr / 50% 50%;
    min-height: 400px;
    max-height: 500px;
  }
}

.content {
  &__image {
    grid-area: img;
    box-sizing: border-box;

    & > img {
      height: auto;
      max-width: 81%;
      width: 81%;
    }
  }

  &__info {
    grid-area: info;
    box-sizing: border-box;
    color: white;
    padding: 4rem 3rem;
  }

  &__image, &__info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
}

.info {
  &__title {
    font-family: 'Jost', sans-serif;
    $l_height: 3.5rem;
    width: 80%;
    font-weight: bold;
    font-size: 48px;
    line-height: $l_height;
    letter-spacing: -4px;
    text-transform: uppercase;

    & > span {
      font-family: 'Jost', sans-serif;
      display: block;
      background-clip: text;
    }
  }

  &__description {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
  }

  &__actions {
    font-family: 'Jost', sans-serif;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
}

.desc {
  //&__text {
  //
  //}
  &__lessons {
    margin-left: 5px;
    font-weight: bold;
  }
}

.acts {
  &__all, &__start {
    padding: .5rem 2.5rem;
    border-radius: 2.5rem;
    font-weight: bold;
  }

  &__all {
    background-color: transparent;
  }

  &__start {
  }
}

@media (max-width: 768px) {

  .hero {
    max-height: 720px;
    margin-bottom: 0px;
    background: transparent;

  }
  .hero__content.content {
    max-height: inherit;
    margin-top: 0;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template: "img" auto "info" 1fr / 100%; // Reestructura el grid para apilar imagen sobre texto

    &__image, &__info {
      width: 100%; // Asegura que ambos componentes ocupen todo el ancho disponible
      height: auto; // Permite que la altura sea automática y se ajuste al contenido
    }

    &__image {
      order: -1; // Coloca la imagen antes del contenido de información
    }

    &__info {
      padding: 0; // Reduce el padding en dispositivos móviles para mejor ajuste
      align-items: flex-start; // Centra el contenido de la info verticalmente
      text-align: center; // Alinea el texto al centro
    }

    .info__description {
      max-height: 150px;
    }

    .info__title {
      font-size: 21px;
    }

    .info__title, .info__description, .info__actions {
      align-items: center; // Alinea los elementos internos al centro
      justify-content: center; // Centra el contenido horizontalmente
      width: 100%; // Asegura que ocupen todo el ancho disponible
    }

    .acts__start, .acts__all {
      width: auto; // Permite que los botones ajusten su ancho al contenido
      margin: 0 auto; // Centra los botones horizontalmente
      display: block; // Muestra los botones en bloque para asegurar que ocupen su propia línea
    }
  }

  .content {
    &__image {
      grid-area: img;
      box-sizing: border-box;

      & > img {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .info {
    row-gap: 0.5rem;

    &__title {
      margin-top: 0;
      width: 100%;
      font-weight: bold;
      font-size: 21px;
      line-height: 1.5rem;
      letter-spacing: 0;
      text-transform: uppercase;

      & > span {
        font-family: 'Jost', sans-serif;
        display: block;
        background-clip: text;
        margin-top: 0 !important;
      }
    }

    &__description {
      font-family: 'Poppins', sans-serif;
      font-size: 0.8rem;
      height: auto;
      line-height: 1.2rem;
      letter-spacing: 0px;
    }

    &__actions {
      font-family: 'Jost', sans-serif;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
    }
  }

  .desc {
    &__lessons {
      margin-left: 5px;
      font-weight: bold;
    }
  }

  .acts {
    &__all, &__start {
      padding: .5rem 1.2rem;
      border-radius: 2.5rem;
      font-weight: bold;
      z-index: 300;
    }

    &__all {
      background-color: transparent;
    }

    &__start {
    }
  }
}


</style>

<script lang="ts">
import {defineComponent} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {EffectFade, Pagination, Autoplay} from 'swiper';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';
import {PaginationOptions, AutoplayOptions} from 'swiper/types';

type CourseToShow = {
  title: string;
  image: string;
  body: string;
  lessons: number;
  backgroundFrom: string;
  backgroundTo: string;
  textColor: string;
  buttonColor: string;
  textButtonColor: string;
}

export default defineComponent({
  name: "HeroSection",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperModules: [EffectFade, Pagination, Autoplay],
      paginationOpts: {
        clickable: true,
        type: 'bullets',
        bulletClass: "swiper-pagination-bullet bullet-transform",
        dynamicBullets: true,
        dynamicMainBullets: 4
      } as PaginationOptions,
      autoPlayOpts: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
        delay: 3000,
      } as AutoplayOptions,
      coursesToShow: [{
        title: "¿EMPEZANDO A INVERTIR?",
        body: "Toma el control de tu futuro financiero con nuestro curso introductorio. ¿Empezando a invertir? Descubre cómo dar tus primeros pasos en el mundo de las finanzas con nuestro curso de 10 lecciones. ¡Inscríbete ahora y comienza a construir tu camino hacia el éxito financiero!",
        image: require('@/assets/img/hero/banner1.png'),
        lessons: 4,
        backgroundFrom: "#009EEF",
        backgroundTo: '#FFFFFF',
        textColor: '#000000',
        buttonColor: '#FF9C4E',
        textButtonColor: '#000000'
      }, {
        title: "Curso Integral de Inversiones:",
        body: "¿Listo para conquistar los mercados de forex y acciones? Aprende a navegar en el mundo de las inversiones con nuestro curso especializado. Con 10 lecciones prácticas, te guiaremos para que puedas construir un sólido conocimiento en forex y acciones. ¡Inscríbete ahora y toma el control de tu futuro financiero!",
        image: require('@/assets/img/hero/banner2.png'),
        lessons: 74,
        backgroundFrom: '#333156',
        backgroundTo: '#131D7E',
        textColor: '#FFFFFF',
        buttonColor: '#FFFFFF',
        textButtonColor: '#000000'
      }, {
        title: "Contruye tu propio portafolio:",
        body: "¡Sumérgete en el emocionante mundo de las criptomonedas! ¿Listo para explorar el potencial de las monedas digitales? Nuestro curso introductorio te guiará paso a paso en la creación de un sólido portafolio de criptomonedas en 10 lecciones. ¡Inscríbete ahora y comienza a construir tu futuro financiero con las criptos!",
        image: require('@/assets/img/hero/banner3.png'),
        lessons: 65,
        backgroundFrom: '#C0E8FD',
        backgroundTo: '#788BA8',
        textColor: '#000000',
        buttonColor: '#FFC900',
        textButtonColor: '#000000'
      }] as Array<CourseToShow>
    }
  },
  methods: {
    goToRegister() {
      this.$router.push('/register');
    },
    goToCourses() {
      this.$router.push('/courses');
    }
  }
});
</script>
