<template>
   <section class="price__area pt-60 pb-130">
      <div class="container">
         <div class="row">
            <div class="col-xxl-4 offset-xxl-4">
               <div class="section__title-wrapper mb-60 text-center">
                  <h2 class="section__title">
                     <!--                    ¿Por que <br> elegir un curso de <span-->
                     <!--                        class="yellow-bg yellow-bg-big">Bully?<img src="@/assets/img/shape/yellow-bg.png"-->
                     <!--                           alt=""></span>-->
                     {{ $t('home.WhyUs.title') }}
                  </h2>
                  <p>{{ $t('home.WhyUs.message') }}
                  </p>
               </div>
            </div>
         </div>

         <div class="row">
            <div class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
               <div class="price__tab-content">
                  <div class="tab-content" id="nav-tabContent">
                     <div class="tab-pane fade show active" id="nav-annually" role="tabpanel"
                        aria-labelledby="nav-annually-tab">
                        <div class="row">

                           <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <div class="price__item grey-bg mb-30 p-relative price_item">
                                 <img src="@/assets/img/level.png" alt="Discord">
                                 <div class="price__head">
                                    <h3>{{ $t('home.WhyUs.card2.title') }}</h3>
                                 </div>
                                 <div class="price__tag mb-25">
                                    <span>{{ $t('home.WhyUs.card2.body') }}</span>
                                 </div>
                                 <div class="price__features mb-40">
                                 </div>
                                 <router-link to="/register" class="e-btn e-btn-border">{{ $t('actions.knowMore')
                                    }}</router-link>
                              </div>
                           </div>

                           <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <div class="price__item grey-bg mb-30 p-relative price_item_button">
                                 <img src="@/assets/img/discord.png" alt="Discord">
                                 <div class="price__head">
                                    <h3>{{ $t('home.WhyUs.card1.title') }}</h3>
                                 </div>
                                 <div class="price__tag mb-25">
                                   <span>{{ $t('home.WhyUs.card1.body') }}</span>
                                 </div>
                                 <div class="price__features mb-40">
                                 </div>
                                 <router-link to="/register" class="e-btn e-btn-border">{{ $t('actions.knowMore')
                                    }}</router-link>
                              </div>
                           </div>


                           <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4"> <!-- Modificado a col-xxl-4 -->
                              <div class="price__item grey-bg mb-30 p-relative price_item">
                                 <img src="@/assets/img/card.png" alt="Discord">
                                 <div class="price__head">
                                    <h3>{{ $t('home.WhyUs.card3.title') }}</h3>
                                 </div>
                                 <div class="price__tag mb-25">
                                    <span>{{ $t('home.WhyUs.card3.body') }}</span>
                                 </div>
                                 <div class="price__features mb-40">
                                 </div>
                                 <router-link to="/register" class="e-btn e-btn-border">{{ $t('actions.knowMore')
                                    }}</router-link>
                              </div>
                           </div>

                           <img src="@/assets/img/img2.png" alt="" class="d-none d-md-block img_background">

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name: 'PricingArea'
};
</script>

<style>
.price_item {
   border-top: 5px solid blue;
}

.price_item_button {
   border-bottom: 5px solid blue;
}

.img_background {
   position: absolute;
   z-index: -1;
   right: 12%;
   max-width: 18rem;
}
</style>
