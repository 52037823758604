<template>
  <div class="tab-pane fade" id="member" role="tabpanel" aria-labelledby="member-tab">
    <div class="course__member mb-45">
      <div class="course__member-item">
        <div class="row align-items-center">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6">
            <div class="course__member-thumb d-flex align-items-center">
              <img src="@/assets/img/course/instructor/course-instructor-1.jpg" alt="">
              <div class="course__member-name ml-20">
                <h5>Shahnewaz Sakil</h5>
                <span>Engineer</span>
              </div>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-45">
              <h5>07</h5>
              <span>Courses</span>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-70">
              <h5>05</h5>
              <span>Review</span>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-85">
              <h5>3.00</h5>
              <span>Rating</span>
            </div>
          </div>
        </div>
      </div>
      <div class="course__member-item">
        <div class="row align-items-center">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6">
            <div class="course__member-thumb d-flex align-items-center">
              <img src="@/assets/img/course/instructor/course-instructor-2.jpg" alt="">
              <div class="course__member-name ml-20">
                <h5>Lauren Stamps</h5>
                <span>Teacher</span>
              </div>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-45">
              <h5>05</h5>
              <span>Courses</span>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-70">
              <h5>03</h5>
              <span>Review</span>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-85">
              <h5>3.00</h5>
              <span>Rating</span>
            </div>
          </div>
        </div>
      </div>
      <div class="course__member-item">
        <div class="row align-items-center">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6 ">
            <div class="course__member-thumb d-flex align-items-center">
              <img src="@/assets/img/course/instructor/course-instructor-3.jpg" alt="">
              <div class="course__member-name ml-20">
                <h5>Jonquil Von</h5>
                <span>Associate</span>
              </div>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-45">
              <h5>09</h5>
              <span>Courses</span>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-70">
              <h5>07</h5>
              <span>Review</span>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
            <div class="course__member-info pl-85">
              <h5>4.00</h5>
              <span>Rating</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Course} from '@/core/domain/entities/course.entity';
import {PropType} from 'vue';

export default {
  name: 'MemberTab',
  props: {
    course: {
      type: Object as PropType<Course>,
      required: true
    }
  }
};
</script>
