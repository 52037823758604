import CryptoJS from 'crypto-js';


export class CryptoUtils {

    private static secretKey: string = process.env.VUE_APP_SECRET_KEY || '1562189382odghfik4';

    public static encryptData(data: string): string {
        console.log('key', this.secretKey)
        return CryptoJS.AES.encrypt(data, this.secretKey).toString();
    }


    public static decryptData(ciphertext: string): string {
        try {
            const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            if (!originalText) throw new Error('Fallo al descifrar');
            return originalText;
        } catch (e) {
            console.error('Error al descifrar:', e);
            return '';
        }
    }

    public static decryptFromStorage(key: string) {
        const storedEncryptedData: string | null = localStorage.getItem(key);
        if (storedEncryptedData) {
            return this.decryptData(storedEncryptedData);
        }
        return null;
    }

}