<template>

  <div class="col-xxl-4 col-xl-4 col-lg-4">
    <div class="course__sidebar pl-70 p-relative">
      <div class="course__curriculum">
        <button class="e-btn e-btn-7 mb-15" @click="showModal"
                v-if="user.role === RoleConstants.INSTRUCTOR || course.ownerId === user.userId">
          {{ $t('course.addModule') }}
        </button>
        <div class="payment-method">
          <div class="accordion" id="checkoutAccordion">
            <div class="accordion-item" v-for="(module, index) in course.modules" :key="module.courseModuleId">
              <h2 class="accordion-header" :id="`heading${index}`">
                <button class="accordion-button" type="button"
                        :class="{'collapsed': index !== 0}"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#${module.courseModuleId}`"
                        :aria-expanded="index === 0 ? 'true' : 'false'"
                        :aria-controls="`${module.courseModuleId}`">
                  {{ module.title }}
                </button>
              </h2>
              <div :id="module.courseModuleId" class="accordion-collapse collapse" :class="{'show': index === 0}"
                   :aria-labelledby="`heading${index}`" data-bs-parent="#checkoutAccordion">
                <div class="accordion-body">
                  <div v-for="section in module.sections" :key="section.title+index"
                       class="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                    <div class="course__curriculum-info" @click="loadVideo(section.fileLinks.fileLink)">
                      <svg class="document" viewBox="0 0 24 24">
                        <path class="st0" d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z"/>
                        <polyline class="st0" points="14,2 14,8 20,8 "/>
                        <line class="st0" x1="16" y1="13" x2="8" y2="13"/>
                        <line class="st0" x1="16" y1="17" x2="8" y2="17"/>
                        <polyline class="st0" points="10,9 9,9 8,9 "/>
                      </svg>
                      <h3><!--<span>Reading:</span> -->{{ section.title }}</h3>
                    </div>
                    <div class="course__curriculum-meta">
                      <span class="time"> <i class="fa fa-clock"></i> {{ section.duration }}</span>
                      <!--                  <span class="question">2 questions</span>-->
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button v-if="user.role === RoleConstants.INSTRUCTOR || course.ownerId === user.userId"
                            class="e-btn e-btn-7 d-flex align-items-center gap-2"
                            type="button"
                            data-toggle="modal"
                            data-target="#addLectureModal"
                            :title="$t('module.addSection')"
                            @click="showSectionModal(module.courseModuleId, module.title)">
                      <i class="fa fa-plus white-color"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- video modal  start-->
  <div>
    <b-modal id="modal-module" centered v-model="isModalVisible">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-body">

              <div class="contact__form">
                <form>
                  <div class="row">
                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('module.title') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <input type="text" name="title" v-model="moduleTitle">
                      </div>
                    </div>
                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('module.duration') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <input type="text" name="title" v-model="moduleDuration">
                      </div>
                    </div>

                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('module.description') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <textarea name="description" v-model="moduleDescription"></textarea>
                      </div>
                    </div>
                    <div class="col-xxl-12">
                      <div class="contact__form-agree  d-flex align-items-center mb-20">
                        <input class="e-check-input" type="checkbox" id="e-agree">
                        <label class="e-check-label" for="e-agree">{{ $t('signUp.accept') }}<a
                            href="#">{{ $t('signUp.terms') }}</a></label>
                      </div>
                    </div>
                    <div class="col-xxl-12">
                      <div class="contact__btn">
                        <button type="button" @click="saveModule" class="e-btn">{{ $t('course.addModule') }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  <!-- video modal  end-->

  <!-- section modal  start-->
  <div>
    <b-modal id="modal-section" centered v-model="isModalSectionVisible">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-body">
              <h3> {{ moduleTitle }}</h3>
              <div class="contact__form">
                <form>
                  <div class="row">
                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('section.title') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <input type="text" name="title" v-model="sectionTitle">
                      </div>
                    </div>
                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('section.duration') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <input type="text" name="title" v-model="sectionDuration">
                      </div>
                    </div>

                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('section.description') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <textarea name="description" v-model="sectionDescription"></textarea>
                      </div>
                    </div>

                    <div class="custom-upload col-12">
                      <label for="media" class="custom-upload-label">
                        <i class="fas fa-cloud-upload-alt custom-upload-icon"></i>
                        <br/>
                        {{ uploadedFileName || 'Arrastra y suelta o selecciona un archivo (imagen/video)' }}
                      </label>
                      <input type="file" class="custom-upload-input" id="media" @change="handleMediaUpload" required/>
                    </div>

                    <div class="col-xxl-12">
                      <div class="contact__btn">
                        <button type="button" @click="saveSection" class="e-btn">{{ $t('module.addSection') }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  <!-- section modal  end-->

</template>

<style scoped lang="scss">
.module-modal-body {
  max-width: 600px;
  background: inherit;
  border-radius: 16px;
}

.custom-upload {
  position: relative;
  overflow: hidden;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
}

.custom-upload-label {
  display: block;
  cursor: pointer;
}

.custom-upload-icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.custom-upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

</style>
<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {RoleConstants} from '@/core/domain/constants/role.constants';
import {Course} from '@/core/domain/entities/course.entity';
import {mapGetters, useStore} from 'vuex';
import {key} from '@/infrastructure/di/store';
import {LecturesUseCase} from '@/core/usecases/lectures.usecase';
import {CoursesUseCase} from "@/core/usecases/courses.usecase";
import {useToast} from "vue-toastification";


export default defineComponent({
  name: 'CurriculumSideBar',
  props: {
    loadLink: Function,
    course: {
      type: Object as PropType<Course>,
      required: true
    },
  },
  setup() {
    const store = useStore(key);
    const lecturesUseCase = new LecturesUseCase(store);
    const coursesUseCase = new CoursesUseCase(store);
    return {
      store,
      lecturesUseCase,
      coursesUseCase
    }
  },
  data() {
    return {
      showAddLectureForm: false,
      moduleTitle: '',
      selectedModuleId: '',
      moduleDescription: '',
      moduleDuration: '',
      isModalVisible: false,
      sectionTitle: '',
      sectionDescription: '',
      sectionDuration: '',
      isModalSectionVisible: false,
      file: {} as File,
      uploadedFileName: '',
      toast: useToast(),
      validFormats: ['mp4', 'avi', 'mov', 'video/mp4', 'video/avi']
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    loadVideo(fileLink: string) {
      if (this.loadLink) {
        this.loadLink(fileLink);
      }
    },
    showSectionModal(courseModuleId: string, moduleTitle: string) {
      this.isModalSectionVisible = true;
      this.moduleTitle = moduleTitle;
      this.selectedModuleId = courseModuleId;
    },
    handleMediaUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.uploadedFileName = file.name;
      }
    },
    async saveModule() {
      if (this.moduleTitle && this.course.courseId) {
        const response = await this.coursesUseCase.addModule(this.moduleTitle, this.moduleDescription, this.moduleDuration, this.course.courseId);
        console.log(response);
        this.isModalVisible = false;
      }
    },
    async saveSection() {
      if (!this.file) {
        this.toast.info(this.$t('section.shouldAddVideo'));
        return;
      }
      console.log(this.file.type)
      if (!this.validFormats.includes(this.file.type.toLowerCase())) {
        this.toast.info(this.$t('section.invalidFormat'));
        return;
      }
      if (this.sectionTitle && this.selectedModuleId) {
        const response = await this.coursesUseCase.addSection(this.sectionTitle, this.sectionDescription, this.sectionDuration, this.selectedModuleId, this.file);
        console.log(response);
        this.isModalSectionVisible = false;
      }
    }
  },
  computed: {
    RoleConstants() {
      return RoleConstants
    },
    ...mapGetters(['user']),
    ...mapGetters(['course']),
  },
  created() {
  }
});
</script>