<template>
  <Header  headerShadow="header__area header__padding header__padding-2 header__shadow "/>
  <div class="course">
    <div class="course__welcome welcome">
      <div class="welcome__figure"></div>
      <div class="welcome__message wmessage">
        <span class="wmessage__name">Bienvenida  {{ username }}</span>
        <span class="wmessage__date">{{ date }}</span>
      </div>
      <div class="welcome__search search">
        <label class="search__group sgroup" for="searchInCourse">
          <img :src="SearchIcon" class="sgroup__icon" alt="Search Icon"/>
          <input placeholder="Search" id="searchInCourse" type="search" class="sgroup__input">
        </label>
      </div>
    </div>
    <div class="course__content content">
      <div class="content__sidebar sidebar">
        <span class="sidebar__title">{{ course.name }}</span>
        <div class="sidebar__content scontent">
          <div class="scontent__card card" v-for="(item, index) in chapters" :key="item.name+index">
            <div class="card__info cinfo"
                 :class="`${(item.watched || chapters[index - 1]?.watched) ? 'card__info--active' : ''}`">
              <span class="cinfo__title">{{ item.name }}</span>
              <div class="cinfo__duration ciduration">
                <img :src="ClockIcon" class="ciduration__icon" alt="Clock Icon"/>
                <span class="ciduration__time">{{ item.duration }}</span>
              </div>
            </div>
            <img class="card__icon" :src="CheckedIcon" v-if="item.watched" alt="Checked Icon"/>
            <img class="card__icon" :src="PlayIcon" v-if="!!chapters[index - 1]?.watched" alt="Play Icon"/>
            <img class="card__icon" :src="LockIcon" v-if="!(item.watched || !!chapters[index - 1]?.watched)" alt="Lock Icon"/>
          </div>
        </div>
      </div>
      <div class="content__chapter">
        <div class="video">
          <vue-plyr>
            <div data-plyr-provider="youtube" data-plyr-embed-id="mKCcki6azHo"></div>
          </vue-plyr>
        </div>
      </div>
    </div>
  </div>
  <Footer footerPadding="true"/>
</template>

<script>
import Header from '@/presentation/components/Home/Header.vue';
import Footer from '@/presentation/components/Home/Footer.vue';
import SearchIcon from '@/assets/svg/search.svg';
import ClockIcon from '@/assets/svg/clock.svg';
import PlayIcon from '@/assets/svg/play.svg';
import CheckedIcon from '@/assets/svg/checked.svg';
import LockIcon from '@/assets/svg/lock.svg';

export default {
  name: 'WatchCourse',
  components: {
    Header,
    Footer
  },
  setup() {
    return {
      SearchIcon,
      ClockIcon,
      PlayIcon,
      CheckedIcon,
      LockIcon
    }
  },
  data() {
    return {
      username: 'Amelia',
      date: 'Mie, 7 de Marzo 2024',
      chapters: [
        {
          name: 'Intro',
          duration: '12 min',
          watched: true
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
        {
          name: 'Lorem Ipsum',
          duration: '12 min',
          watched: false
        },
      ],
      course: {
        name: 'DINERO DESBLOQUEADO'
      },
      currentChapter: {
        index: 1,
        title: 'Intro',
        subtitle: 'El inicio',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        homework: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        savedForLater: false
      }
    }
  }
};
</script>

<style scoped lang="scss">
.course {
  padding: 1rem 5rem;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  &__welcome {
    height: 95px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 30% 70%;
    padding: 30px;
    grid-template-areas: "sidebar chapter";
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    position: relative;
  }
}

.welcome {
  &__figure {
    position: absolute;
    z-index: 1;
    width: 75px;
    height: 70px;
    background: rgba(94, 148, 253, 0.4);
    border-radius: 30px 0 30px 0;
  }

  &__message {
    height: 100%;
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0 0 30px;
  }

  .wmessage {
    &__name {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #3E435D;
    }

    &__date {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #ADA7A7;
    }
  }

  .search__group {
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
    min-width: 190px;
    max-width: 260px;
  }

  .sgroup {
    &__input {
      width: 100%;
      height: 40px;
      line-height: 28px;
      padding: 0 1rem;
      padding-left: 2.5rem;
      border: 2px solid transparent;
      border-radius: 8px;
      outline: none;
      background-color: #f3f3f4;
      color: #0d0c22;
      transition: .3s ease;

      &::placeholder {
        color: #9e9ea7;
      }
    }

    &__icon {
      position: absolute;
      left: 1rem;
      fill: #9e9ea7;
      width: 1rem;
      height: 1rem;
    }
  }
}

.content {
  &__sidebar {
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  &__chapter {
    grid-area: chapter;
  }
}

.sidebar {
  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}

.scontent {
  &__card {
    padding: .75rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.card {
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
  }
  &__icon {
    height: 30px;
    width: auto;
  }
}

.cinfo {
  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000;
  }

  &__duration {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2px;
  }
}

.ciduration {
  &__icon {
    height: 18px;
    width: auto;
  }

  &__time {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    color: #000;
  }
}

.video {
  padding: 1rem;
}
</style>
