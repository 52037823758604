<template>
  <section class="d-flex justify-content-center align-items-center banner-section">
    <div class="container-left mr-3">
      <div>
        <h2 class="text-white">{{$t('home.startFree')}}</h2>
        <button type="button" class="btn button" @click="goToRegister">{{$t('header.signUp')}}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M10.854 7.646a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.293 7l-2.147 2.146a.5.5 0 1 0 .708.708l3-3a.5.5 0 0 0 0-.708z"/>
            <path fill-rule="evenodd" d="M11 8a.5.5 0 0 0-.5-.5H1a.5.5 0 0 0 0 1h9.5a.5.5 0 0 0 .5-.5z"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="container-right d-flex align-items-center">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <h5 class="card-title font-weight-bold">10 años</h5>
              <p class="card-text">En el sector infoproductos</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <h5 class="card-title font-weight-bold">Unica plataforma</h5>
              <p class="card-text">all in one sector finanzas</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <h5 class="card-title font-weight-bold">600</h5>
              <p class="card-text">afiliados</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "BannerArea",
  methods: {
    goToRegister() {
      this.$router.push('/register');
    },
  }
});
</script>

<style scoped>
.button {
  border-radius: 40px;
  background-color: rgb(221, 221, 221);
}

.container-left {
  max-width: 30vw;
}

.container-right {
  display: flex;
  align-items: center;
}

.card {
  border-top-left-radius: 60px;
  border-bottom-right-radius: 60px;
  text-align: center;
  margin: 20px;
}

.card-body {
  width: 150px;
  height: 180px
}

.card-title {
  font-size: 20px;
}

.banner-section {
  background-image: url('@/assets/img/iniciagratiss.png');
  background-size: cover;
  background-position: center;
  height: 700px;
}

@media screen and (max-width: 768px) {
  .container-left, .container-right {
    max-width: 90vw;
    margin: auto;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  #bad-text {
    display: none;
  }
}
</style>
