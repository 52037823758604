<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow "/>
  <section class="page__title-area pt-120 pb-90">
    <div class="page__title-shape">
      <img class="page-title-shape-9" src="@/assets/img/page-title/page-title-shape-6.png" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="course__wrapper">
            <div class="page__title-content mb-25">
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t('menu.home') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/courses">{{ $t('header.courses') }}</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ course?.detail?.title }}</li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">{{ course?.detail?.category }}</span>
              <h5 class="page__title-3">{{ course?.detail?.title }}</h5>
            </div>
            <div class="course__meta-2 d-sm-flex mb-30">
              <div class="course__update mr-80 mb-30">
                <h5>{{ $t('course.lastUpdate') }}</h5>
                <p>{{ $d(new Date(), 'short') }}</p>
              </div>
              <div class="course__rating-2 mb-30">
                <h5>{{ $t('course.review') }}</h5>
                <div class="course__rating-inner d-flex align-items-center">
                  <ul>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                  </ul>
                  <p>{{ course?.rating?.toFixed(1) }}</p>
                </div>
              </div>
            </div>
            <div class="course__img w-img mb-30 position-relative">
              <div class="video" v-if="showVideo">
                <vue-plyr>
                  <video controls crossorigin playsinline>
                    <source :src="currentVideo" type="video/mp4">
                    <!-- Puedes agregar más fuentes para diferentes formatos, como WebM -->
                    <source src="path_to_your_video.webm" type="video/webm">
                    <!-- Mensaje para navegadores que no soportan el elemento video -->
                    Tu navegador no soporta la etiqueta video.
                  </video>
                </vue-plyr>
              </div>
              <img v-else
                  :src="currentVideo"
                  alt="Cover Image" class="img-fluid rounded-top-right-bottom-left"/>
            </div>

            <div class="course__tab-2 mb-45">
              <ul class="nav nav-tabs" id="courseTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="description-tab" data-bs-toggle="tab"
                          data-bs-target="#description" type="button" role="tab" aria-controls="description"
                          aria-selected="true"><i class="icon_ribbon_alt"></i>
                    <span>{{ $t('course.description') }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="curriculum-tab" data-bs-toggle="tab"
                          data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum"
                          aria-selected="false"><i class="icon_book_alt"></i> <span>{{ $t('course.curriculum') }}</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review"
                          type="button" role="tab" aria-controls="review" aria-selected="false"><i
                      class="icon_star_alt"></i> <span>{{ $t('course.reviews') }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="member-tab" data-bs-toggle="tab" data-bs-target="#member"
                          type="button" role="tab" aria-controls="member" aria-selected="false"><i
                      class="fal fa-user"></i> <span>{{ $t('course.members') }}</span></button>
                </li>
              </ul>
            </div>
            <div class="course__tab-content mb-95">
              <div class="tab-content" id="courseTabContent">

                <div class="tab-pane fade show active" id="description" role="tabpanel"
                     aria-labelledby="description-tab">
                  <div class="course__description">
                    <h3>{{ $t('course.overview') }}</h3>
                    <p>{{ course?.detail?.description }}</p>

                    <div class="course__tag-2 mb-35 mt-35">
                      <i class="fal fa-tag"></i>
                      <a href="#">{{ course?.detail?.category }}</a>
                    </div>
                    <!--                    <div class="course__instructor mb-45">
                                          <h3>Other Instructors</h3>
                                          <div class="course__instructor-wrapper d-md-flex align-items-center">
                                            <div class="course__instructor-item d-flex align-items-center mr-70">
                                              <div class="course__instructor-thumb mr-20">
                                                <img src="@/assets/img/course/teacher/teacher-3.jpg" alt="">
                                              </div>
                                              <div class="course__instructor-content">
                                                <h3>Eleanor Fant</h3>
                                                <p>Instructor</p>
                                              </div>
                                            </div>
                                            <div class="course__instructor-item d-flex align-items-center mr-70">
                                              <div class="course__instructor-thumb mr-20">
                                                <img src="@/assets/img/course/teacher/teacher-2.jpg" alt="">
                                              </div>
                                              <div class="course__instructor-content">
                                                <h3>Lauren Stamps</h3>
                                                <p>Teacher</p>
                                              </div>
                                            </div>
                                            <div class="course__instructor-item d-flex align-items-center mr-70">
                                              <div class="course__instructor-thumb mr-20">
                                                <img src="@/assets/img/course/teacher/teacher-1.jpg" alt="">
                                              </div>
                                              <div class="course__instructor-content">
                                                <h3>Jonquil Von</h3>
                                                <p>Associate</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>-->
                  </div>
                </div>

                <!-- CurriculumTab start -->
                <CurriculumTab :course="course"/>
                <!-- CurriculumTab end -->

                <!-- CurriculumTab start -->
                <ReviewTab :course="course"/>
                <!-- CurriculumTab end -->

                <!-- CurriculumTab start -->
                <MemberTab :course="course"/>
                <!-- CurriculumTab end -->

                <div class="course__share">
                  <h3>Share :</h3>
                  <ul>
                    <li>
                      <a href="#" class="fb"><i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="tw"><i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="pin"><i class="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="course__related">
              <div class="row">
                <div class="col-xxl-12">
                  <div class="section__title-wrapper mb-40">
                    <h2 class="section__title">Related <span class="yellow-bg yellow-bg-big">Course<img
                        src="@/assets/img/shape/yellow-bg.png" alt=""></span></h2>
                    <p>You don't have to struggle alone, you've got our assistance and help.</p>
                  </div>
                </div>
              </div>
              <!-- slider courses start-->
              <SliderCourses/>
              <!-- slider courses end-->
            </div>
          </div>
        </div>

        <!-- course side bar start -->
        <CourseSidebar v-if="!isInstructor()" :course-data="course"/>
        <CurriculumSideBar v-else :course="course" :load-link="updateLink"/>
        <!-- course side bar end -->
      </div>
    </div>
  </section>

  <!-- Footer -->
  <Footer footerPadding="true"/>

</template>

<script lang="ts">
import Header from '../components/Home/Header.vue';
import Footer from '../components/Home/Footer.vue';
import SliderCourses from '../components/CourseDetails/SliderCourses.vue';
import CourseSidebar from '../components/CourseDetails/CourseSidebar.vue';
import CurriculumTab from '../components/CourseDetails/CurriculumTab.vue';
import CurriculumSideBar from '@/presentation/components/CourseDetails/CurriculumSideBar.vue';
import ReviewTab from '../components/CourseDetails/ReviewTab.vue';
import MemberTab from '../components/CourseDetails/MemberTab.vue';
import {Course} from '@/core/domain/entities/course.entity';
import {mapGetters, useStore} from 'vuex';
import {key} from '@/infrastructure/di/store';
import {CoursesUseCase} from '@/core/usecases/courses.usecase';
import {defineComponent} from 'vue';
import {RoleConstants} from '@/core/domain/constants/role.constants';
import {AuthUseCase} from "@/core/usecases/auth.usecase";

export default defineComponent({
  name: "CourseDetailsPage",
  components: {
    Header,
    SliderCourses,
    CourseSidebar,
    CurriculumTab,
    CurriculumSideBar,
    ReviewTab,
    MemberTab,
    Footer
  },
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      course: {} as Course,
      currentVideo: '',
      showVideo: false
    }
  },
  methods: {
    async isInstructor(): Promise<boolean> {
      const authUseCase = new AuthUseCase(this.store);
      const user = await authUseCase.getUserData();
      const instructor = user.role === RoleConstants.INSTRUCTOR;
      return instructor;
    },

    updateLink(link: string) {
      console.log('link', link)
      this.currentVideo = link;
      this.showVideo = true;
    },
    async getCourse() {
      const useCase = new CoursesUseCase(this.store);
      if (this.id) {
        const c = await useCase.getCourse(this.id as string, this.$i18n.locale);
        if (c) {
          this.course = c;
          this.currentVideo = c?.coverLinks?.fileLink ? require(c?.coverLinks?.fileLink) : require(`@/assets/img/course/curso_defi.png`);
        }
        console.log('coursee ', this.course);
      }
    }
  },
  async mounted() {

    console.log(this.$route.params.id);
  },
  computed: {
    RoleConstants() {
      return RoleConstants
    },
    ...mapGetters(['user']),
  },
  created() {
    this.getCourse();
  }
});
</script>
