import {createApp} from 'vue'
import App from './App.vue'
import 'bootstrap/scss/bootstrap.scss';
import 'swiper/css/bundle';
import './assets/css/fontAwesome5Pro.css';
import 'bootstrap';
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import './assets/scss/main.scss';

import VuePlyr from '@skjnldsv/vue-plyr';
import '@skjnldsv/vue-plyr/dist/vue-plyr.css';

import {key, store} from './infrastructure/di/store';
import router from './infrastructure/di/router';

import i18n from './core/locales';

import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const app = createApp(App);
app.use(VuePlyr, {
  plyr: {}
});
app.use(Toast, { position: POSITION.TOP_RIGHT })
app.use(router)
app.use(BootstrapVue3);
app.use(store, key);
app.use(i18n);
app.mount('#app');
