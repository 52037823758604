type ShowCoursesAsType = 'grid' | 'list';

type UxState = {
  showCoursesAs: ShowCoursesAsType
};

const state: UxState = {
  showCoursesAs: 'grid',
}

const getters = {
  showCoursesAs: (state: UxState) => state.showCoursesAs,
}

const mutations = {
  setShowCoursesAs: (state: UxState, value: ShowCoursesAsType) => {
    state.showCoursesAs = value
  }
}

const actions = {
  setShowCoursesAs: ({commit}, value: ShowCoursesAsType) => {
    commit('setShowCoursesAs', value);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
