import {Store} from 'vuex';
import {IAuthRepository} from '@/core/domain/repositories/auth.repository';
import {AuthRepository} from '@/infrastructure/data/repositories/auth.repository.impl';
import {RoleConstants} from "@/core/domain/constants/role.constants";
import {User} from "@/core/domain/entities/user.entity";
import {StorageConstants} from "@/core/domain/constants/storage.constants";
import Cookies from 'js-cookie';
import {CryptoUtils} from "@/core/shared/domain/CryptoUtils";
import {InstructorInputModel} from '@/infrastructure/data/models/instructorInput.model';

export class AuthUseCase {
    private _repo: IAuthRepository;
    private _store: Store<unknown>;

    constructor(store: Store<unknown>) {
        this._repo = new AuthRepository();
        this._store = store
    }

    async login(email: string, password: string): Promise<boolean> {
        const res = await this._repo.login(email, password);

        if (res && res.accessToken) {
            AuthUseCase.saveAuthInfo(res.accessToken, res.refreshToken);
            return true;
        }
        return false;
    }

    static saveAuthInfo(accessToken: string, refreshToken: string) {
        localStorage.setItem(StorageConstants.IS_AUTHENTICATED, CryptoUtils.encryptData('true'));
        localStorage.setItem(StorageConstants.TOKEN, accessToken);
        localStorage.setItem(StorageConstants.REFRESH_TOKEN, refreshToken);
        // Configurar cookies con js-cookie
      /*  Cookies.set(StorageConstants.TOKEN, accessToken, {expires: 6 / 24, secure: true, sameSite: 'lax'}); // Expira en 6 hora
        Cookies.set(StorageConstants.REFRESH_TOKEN, refreshToken, {expires: 2, secure: true, sameSite: 'lax'}); // Expira en 3 día*/
    }

    async getUserData(): Promise<User> {
        const res = await this._repo.getUserData();
        await this._store.dispatch('setUser', res);
        localStorage.setItem(StorageConstants.ROLE, CryptoUtils.encryptData(res.role));
        return res;
    }

    async register(email: string, password: string, fullName: string, role: string = RoleConstants.USER): Promise<boolean> {
        const res = await this._repo.register(email, password, fullName, role);
        return !!res;
    }

    async registerInstructor(body: InstructorInputModel): Promise<boolean> {
        return await this._repo.registerInstructor(body);
    }

    static logOut(){
        localStorage.setItem(StorageConstants.IS_AUTHENTICATED, CryptoUtils.encryptData('false'));
        localStorage.setItem(StorageConstants.ROLE_INSTRUCTOR, CryptoUtils.encryptData('false'));
        localStorage.removeItem(StorageConstants.TOKEN);
        localStorage.removeItem(StorageConstants.REFRESH_TOKEN);
    }

    static isAuthenticated(): boolean {
        const value = CryptoUtils.decryptFromStorage(StorageConstants.IS_AUTHENTICATED);
        return value === 'true';
    }
    static isInstructor(): boolean {
        const value = CryptoUtils.decryptFromStorage(StorageConstants.ROLE);
        return value === StorageConstants.ROLE_INSTRUCTOR;
    }

}
