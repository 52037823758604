<template>
  <header>
    <div id="header-sticky" :class="`${isSticky ? 'header__area header__transparent header__padding sticky'
      : 'header__area header__transparent header__padding'} ${header__white && header__white}`">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
            <div class="header__left d-flex">
              <div class="logo">
                <router-link to="/">
                  <img v-if="header__white && !isSticky" src="@/assets/img/logo/bullfy.png" alt="logo"/>

                  <img v-else src="@/assets/img/logo/bullfy.png" alt="logo"/>
                </router-link>
              </div>
              <div class="header__category d-none d-lg-block">
              </div>
            </div>
          </div>
          <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
            <div class="
                header__right
                d-flex
                justify-content-end
                align-items-center
              ">
              <div :class="`main-menu ${header__white && 'main-menu-3'}`">
                <nav id="mobile-menu" class="d-none d-xl-block">
                  <ul>
                    <li>
                      <router-link to="/">{{ $t('menu.home') }}</router-link>
                    </li>
                    <li class="has-dropdown">
                      <router-link to="/courses">{{ $t('header.courses') }}</router-link>
                      <ul class="submenu">
                        <li>
                          <router-link to="/courses">{{ $t('header.courses') }}</router-link>
                        </li>
                        <li>
                          <router-link to="/courses-sidebar">{{ $t('header.coursesList') }}</router-link>
                        </li>
                        <!--                        <li>
                                                  <router-link to="/courses-sidebar">Course sidebar</router-link>
                                                </li>-->
                        <li>
                          <router-link to="/course/watch">{{ $t('header.watchCourse') }}</router-link>
                        </li>
                        <li v-if="!isAuthenticated">
                          <router-link to="/instructor-register">{{ $t('footer.becomeTeacher') }}</router-link>
                        </li>
                      </ul>
                    </li>

                    <li class="has-dropdown" v-if="isAuthenticated">
                      <router-link to="/courses">{{ $t('menu.profile') }}</router-link>
                      <ul class="submenu">
<!--                        <li>
                          <router-link to="/instructor-details">{{ $t('menu.profile') }}</router-link>
                        </li>-->
                        <li>
                          <a href="https://consumer.bullfy.com/">{{ $t('menu.myCourses') }}</a>
                        </li>
                        <li>
                          <a @click="logOut">{{ $t('menu.logOut') }}</a>
                        </li>
                      </ul>
                    </li>

                  </ul>
                </nav>
              </div>
              <div class="header__search p-relative ml-50 d-none d-md-block" v-if="showSearch">
                <form action="#">
                  <input type="text" placeholder="Search..."/>
                  <button type="submit"><i class="fad fa-search"></i></button>
                </form>
                <div class="header__cart">
                  <a @click="handleMiniCart" href="javascript:void(0);" class="cart-toggle-btn">
                    <div class="header__cart-icon">
                      <svg viewBox="0 0 24 24">
                        <circle class="st0" cx="9" cy="21" r="1"/>
                        <circle class="st0" cx="20" cy="21" r="1"/>
                        <path class="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>
                      </svg>
                    </div>
                    <span class="cart-item">2</span>
                  </a>
                </div>
              </div>

              <div class="header__btn ml-20 d-none d-sm-block position-relative" v-if="false">
                <router-link to="/login" class="text-decoration-none text-dark font-weight-bold">
                  {{ $t('header.teachersSignIn') }}
                  <span
                      class="border-bottom position-absolute bottom-0 left-0 w-100 border border-dark">&nbsp;</span>
                </router-link>
              </div>


              <div class="header__btn ml-20 d-none d-sm-block">
                <router-link to="/login" class="e-btn" v-if="!isAuthenticated">{{
                    $t('actions.startNow')
                  }}
                </router-link>
              </div>
              <div class="sidebar__menu d-xl-none">
                <div @click="handleSidebar" class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>


  <!-- cart mini area start -->
  <div class="cartmini__area">
    <div :class="`${showMiniCart ? 'cartmini__wrapper opened' : 'cartmini__wrapper'}`">
      <div class="cartmini__title">
        <h4>{{ $t('shoppingCart.title') }}</h4>
      </div>
      <div @click="handleCartClose" class="cartmini__close">
        <button type="button" class="cartmini__close-btn">
          <i class="fal fa-times"></i>
        </button>
      </div>
      <div class="cartmini__widget">
        <div class="cartmini__inner">
          <ul>
            <li>
              <div class="cartmini__thumb">
                <a href="#">
                  <img src="@/assets/img/course/sm/cart-1.jpg" alt="">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a href="#">Strategy law and organization Foundation </a></h5>
                <div class="product-quantity mt-10 mb-10">
                  <span @click="handleDecreaseValue('valueOne')" class="cart-minus">-</span>
                  <input class="cart-input" type="text" v-model="value"/>
                  <span @click="handleIncreaseValue('valueOne')" class="cart-plus">+</span>
                </div>
                <div class="product__sm-price-wrapper">
                  <span class="product__sm-price">$46.00</span>
                </div>
              </div>
              <a href="#" class="cartmini__del"><i class="fal fa-times"></i></a>
            </li>
            <li>
              <div class="cartmini__thumb">
                <a href="#">
                  <img src="@/assets/img/course/sm/cart-2.jpg" alt="">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a href="#">Fundamentals of music theory Learn new</a></h5>
                <div class="product-quantity mt-10 mb-10">
                  <span @click="handleDecreaseValue('valueTwo')" class="cart-minus">-</span>
                  <input class="cart-input" type="text" v-model="valueTwo"/>
                  <span @click="handleIncreaseValue('valueTwo')" class="cart-plus">+</span>
                </div>
                <div class="product__sm-price-wrapper">
                  <span class="product__sm-price">$32.00</span>
                </div>
              </div>
              <a href="#" class="cartmini__del"><i class="fal fa-times"></i></a>
            </li>
            <li>
              <div class="cartmini__thumb">
                <a href="#">
                  <img src="@/assets/img/course/sm/cart-3.jpg" alt="">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a href="#">Strategy law and organization Foundation </a></h5>
                <div class="product-quantity mt-10 mb-10">
                  <span @click="handleDecreaseValue('valueThree')" class="cart-minus">-</span>
                  <input class="cart-input" type="text" v-model="valueThree"/>
                  <span @click="handleIncreaseValue('valueThree')" class="cart-plus">+</span>
                </div>
                <div class="product__sm-price-wrapper">
                  <span class="product__sm-price">$62.00</span>
                </div>
              </div>
              <a href="#" class="cartmini__del"><i class="fal fa-times"></i></a>
            </li>
          </ul>
        </div>
        <div class="cartmini__checkout">
          <div class="cartmini__checkout-title mb-30">
            <h4>Subtotal:</h4>
            <span>$113.00</span>
          </div>
          <div class="cartmini__checkout-btn">
            <router-link to="/cart" class="e-btn e-btn-border mb-10 w-100"><span></span>
              {{ $t('shoppingCart.viewCart') }}
            </router-link>
            <router-link to="/checkout" class="e-btn w-100"><span></span>{{ $t('shoppingCart.checkout') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div @click="handleCartClose" :class="[showMiniCart ? 'body-overlay opened' : 'body-overlay']"></div>
  <!-- cart mini area end -->

  <!-- sidebar area start -->
  <div :class="`${showSidebar ? 'sidebar__area sidebar-opened' : 'sidebar__area'}`">
    <div class="sidebar__wrapper">
      <div @click="handleSidebarClose" class="sidebar__close">
        <button class="sidebar__close-btn" id="sidebar__close-btn">
          <span><i class="fal fa-times"></i></span>
          <span>close</span>
        </button>
      </div>
      <div class="sidebar__content">
        <div class="logo mb-40">
          <router-link to="/">
            <img src="@/assets/img/logo/bullfy.png" alt="logo">
          </router-link>
        </div>

        <div class="side-info-content sidebar-menu mm-menu">

          <ul>
            <li class="menu-item-has-children has-droupdown"
                v-bind:class="[menuOption.homeDropdown === true ? 'active' : '']">
              <a @click="menuOption.homeDropdown = !menuOption.homeDropdown">{{ $t('menu.home') }}</a>
              <ul class="sub-menu" :class="[menuOption.homeDropdown === true ? 'active' : '',]">
                <li>
                  <router-link to="/home">{{ $t('menu.home') }}</router-link>
                </li>
              </ul>
            </li>


            <li class="menu-item-has-children has-droupdown"
                :class="[menuOption.coursesDropdown === true ? 'active' : '',]">
              <a @click="menuOption.coursesDropdown = !menuOption.coursesDropdown">Courses</a>
              <ul class="sub-menu" :class="[menuOption.coursesDropdown === true ? 'active' : '',]">
                <li>
                  <router-link to="/courses">{{ $t('header.courses') }}</router-link>
                </li>
                <li>
                  <router-link to="/courses-sidebar">{{ $t('header.coursesList') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/course/watch">{{ $t('header.watchCourse') }}
                  </router-link>
                </li>
                <li v-if="!isAuthenticated">
                  <router-link to="/instructor-register">{{ $t('footer.becomeTeacher') }}</router-link>
                </li>
              </ul>
            </li>

            <li class="has-dropdown" v-if="isAuthenticated">
              <router-link to="/instructor-details">{{ $t('menu.profile') }}</router-link>
              <ul class="submenu">
                <li>
                  <router-link to="/instructor-details">{{ $t('menu.profile') }}</router-link>
                </li>
                <li>
                  <router-link to="/courses-sidebar">{{ $t('menu.myCourses') }}</router-link>
                </li>
                <li>
                  <a @click="logOut">{{ $t('menu.logOut') }}</a>
                </li>
              </ul>
            </li>

            <li>

              <router-link to="/login" class="e-btn" v-if="!isAuthenticated">{{
                  $t('actions.startNow')
                }}
              </router-link>
     <!--              <router-link to="/register" class="border-0">{{ $t('header.signUp') }}</router-link>-->
            </li>

          </ul>
        </div>

        <div class="sidebar__search p-relative mt-40 " v-if="false">
          <form action="#">
            <input type="text" placeholder="Search...">
            <button type="submit"><i class="fad fa-search"></i></button>
          </form>
        </div>
        <div class="sidebar__cart mt-30" v-if="false">
          <a href="#">
            <div class="header__cart-icon">
              <svg viewBox="0 0 24 24">
                <circle class="st0" cx="9" cy="21" r="1"/>
                <circle class="st0" cx="20" cy="21" r="1"/>
                <path class="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>
              </svg>
            </div>
            <span class="cart-item">2</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div @click="handleSidebarClose" :class="[showSidebar ? 'body-overlay opened' : 'body-overlay']"></div>
  <!-- sidebar area end -->


</template>

<script>
import {AuthUseCase} from "@/core/usecases/auth.usecase";

export default {
  name: "HomeHeader",
  props: {
    header__white: String,
  },
  data() {
    return {
      isSticky: false,
      showMiniCart: false,
      showSidebar: false,
      showSearch: true,
      value: 1,
      valueTwo: 1,
      valueThree: 1,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        coursesDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
    }
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    handleMiniCart() {
      this.showMiniCart = true;
    },
    handleCartClose() {
      this.showMiniCart = false;
    },
    handleSidebar() {
      this.showSidebar = true;
    },
    handleSidebarClose() {
      this.showSidebar = false;
    },
    handleIncreaseValue(value) {
      if (value === 'valueOne') {
        this.value++
      }
      if (value === 'valueTwo') {
        this.valueTwo++
      }
      if (value === 'valueThree') {
        this.valueThree++
      }

    },
    handleDecreaseValue(value) {
      if (value === 'valueOne' && this.value > 0) {
        this.value--
      }
      if (value === 'valueTwo' && this.valueTwo > 0) {
        this.valueTwo--
      }
      if (value === 'valueThree' && this.valueThree > 0) {
        this.valueThree--
      }
    },
    logOut(){
      console.log('logout')
      AuthUseCase.logOut();
      this.$router.push('/');
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleSticky)
  },
  computed: {
    isAuthenticated() {
      return AuthUseCase.isAuthenticated()
    },
    isInstructor() {
      return AuthUseCase.isInstructor()
    },

  }
};
</script>

<style>
#header-sticky {
  max-width: 100%;
}

</style>
