import {CategoriesRepository} from '../../infrastructure/data/repositories/categories.repository.impl';
import {Store} from 'vuex';
import {ICategoriesRepository} from '@/core/domain/repositories/categories.repository';

export class CategoriesUseCase {
  private _repo: ICategoriesRepository;
  private _store: Store<unknown>;

  constructor(store: Store<unknown>) {
    this._repo = new CategoriesRepository();
    this._store = store;
  }

  async getCategories(language: string): Promise<void> {
    const data = await this._repo.getCategories(language);
    await this._store.dispatch('setCategories', data);
  }
}
