<template>
  <section>
    <div class="row">
      <!-- Columna para la imagen -->
      <div class="col-6 d-none d-md-flex  justify-content-end align-items-center">
        <img src="../../../assets/img/fondo.png" alt="Imagen" class="img-fluid left-login-img">
      </div>

      <div class="col-12 col-md-6 mt-60">
        <!-- Contenido original -->
        <div class="section__title-wrapper text-center mb-35">
          <h2 class="section__title">{{ $t("login.title") }}<br> {{ $t('login.startToTeach') }}</h2>
          <p>¡{{ $t('login.dontHaveAcc') }} <a href="#">{{ $t('actions.registerHere') }}!</a></p>
        </div>
        <!-- Nuevo contenedor para el contenido que quieres desplazar hacia la derecha -->
        <div class="sign__wrapper">
          <!-- Contenido que deseas posicionar más hacia la derecha -->
          <div class="sign__form">
            <form>
              <div class="sign__input-wrapper mb-25">
                <h5>{{ $t('forms.email') }}</h5>
                <div class="sign__input">
                  <input type="email" v-model="email" :placeholder="$t('forms.email')" required
                         pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
                  <i class="fal fa-envelope"></i>
                </div>
                <span v-if="email && !validateEmail(email)"
                      class="error-message">Ingrese un correo electrónico válido</span>
              </div>
              <div class="sign__input-wrapper mb-10">
                <h5>{{ $t('forms.password') }}</h5>
                <div class="sign__input">
                  <input type="password" v-model="password" :placeholder="$t('forms.password')" required>
                  <i class="fal fa-lock"></i>
                </div>
              </div>
              <div class="sign__action d-sm-flex justify-content-between mb-30">
                <div class="sign__forgot">
                  <a href="#">{{ $t('login.forgotPassword') }}</a>
                </div>
              </div>
              <div v-if="!email || !password" class="error-message">Los campos son obligatorios</div>
              <button type="button" @click="login" :disabled="!email || !password" class="e-btn w-100">
                {{ $t('login.title') }}
              </button>
              <div class="sign__new text-center mt-20">
                <p>{{ $t('login.areUNew') }}
                  <router-link to="/register">{{ $t('actions.register') }}</router-link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import {defineComponent} from 'vue';
import {AuthUseCase} from '@/core/usecases/auth.usecase';
import {useStore} from "vuex";
import {key} from "@/infrastructure/di/store";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: 'LoginArea',
  setup() {
    const store = useStore(key);
    const authUseCase = new AuthUseCase(store);
    return {
      authUseCase
    }
  },
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      toast: useToast()
    };
  },
  methods: {
    async login() {
      try {
        console.log('login');
        const res = await this.authUseCase.login(this.email, this.password);
        if (res) {
          const user = await this.authUseCase.getUserData();
          await this.$router.push(`/instructor-details?uid=${user.userId}`);
          this.toast.success(this.$t('course.welcome', ''));
        }else {
          this.toast.error(this.$t('login.invalid'));
        }
      }catch (e){
        this.toast.error(this.$t('login.invalid'));
        console.error(e);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validateEmail(email) {
      // Función para validar el formato de correo electrónico
      const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      return regex.test(email);
    }
  }
});
</script>

<style scoped>

.left-login-img {
  max-width: 60%;
}

.error-message {
  color: red;
  margin-top: 5px;
}
</style>
