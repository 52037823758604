<template>
  <div v-for="(course, index) in courses" :key="course.courseId"
       class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
    <div class="course__item white-bg mb-30 fix">
      <div class="course__thumb w-img p-relative fix max-h">

        <img :src="course.coverLinks ? course.coverLinks.fileLink: courseItems[index]?.courseImage" alt=""
             class="h-max-img">

        <div class="course__tag">
          <!--          <a href="#" :class="course.color">{{ course.category }}</a>-->
          <a href="#">{{ course?.detail?.category ?? courseItems[index]?.category }}</a>
        </div>
      </div>
      <div class="course__content">
        <div class="course__meta d-flex align-items-center justify-content-between">
          <div class="course__lesson">
            <span><i class="far fa-book-alt"></i>{{
                course.detail?.lessons ?? courseItems[index]?.lesson
              }} Modulos</span>
          </div>
          <div class="course__rating">
            <span><i class="icon_star"></i>{{ course.rating }} ({{ course.totalRates }})</span>
          </div>
        </div>
        <h3 class="course__title">
          <a href="#">
            {{ course.detail?.title }}
          </a>
        </h3>
        <div class="course__teacher d-flex align-items-center">
          <div class="course__teacher-thumb mr-15">
            <img :src="course.teacher?.thumbnail ?? require('@/assets/img/course/teacher/teacher.jpg')" alt="">
          </div>
          <h6>
            <a href="#">
              {{ course.teacherName }}
            </a>
          </h6>
        </div>
      </div>
      <div class="course__more d-flex justify-content-between align-items-center">
        <div class="course__status d-flex align-items-center">
          <!--          <span :class="course.color">${{ course.price }}</span>-->
          <span>${{ course.isFree ? 'Free' : course.priceUSD }}</span>
          <!--          <span class="old-price">${{ course.oldPrice }}</span>-->
        </div>
        <div class="course__btn">
          <a href="#">
            {{ $t('actions.coming') }}
            <i class="far fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CourseMixin from '@/mixins/courseItemsMixin';
import {defineComponent, PropType} from 'vue';
import {Courses} from '@/core/domain/entities/course.entity';


export default defineComponent({
  name: 'GridTab',
  mixins: [CourseMixin],
  props: {
    courses: Object as PropType<Courses>
  }
});
</script>

<style>
.max-h {
  max-height: 228px;
}

.h-max-img {
  width: 100% !important;
  max-height: auto !important;
}
</style>
