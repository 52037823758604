<template>
 <router-view/>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    const supportedLanguages = ['en', 'es'];
    const browserLanguage = window.navigator.language.split('-')[0];
    this.$i18n.locale = window.navigator.language;
    this.$i18n.locale = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

  }
}
</script>


