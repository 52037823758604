<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <RegisterForm />
  <Footer footerPadding="true" />
</template>
<script lang="ts">
import Footer from '@/presentation/components/Home/Footer.vue';
import Header from '@/presentation/components/Home/Header.vue';
import RegisterForm from '@/presentation/components/InstructorRegister/RegisterForm.vue';
import {defineComponent} from 'vue';

export default  defineComponent({
  name: 'InstructorRegister',
  components: {
    Header,
    Footer,
    RegisterForm
  }
});
</script>
