import {HttpBase} from '../../di/httpBase';
import {ICoursesDataSource} from '@/core/domain/datasources/courses.datasource';
import {Course, PaginatedCourses} from '@/core/domain/entities/course.entity';

class CoursesDatasource implements ICoursesDataSource {
    private _httpBase: HttpBase;

    constructor() {
        this._httpBase = new HttpBase();
    }

    async getCourses(page: number, limit: number, defaultLanguage: string, categoryId?: string): Promise<PaginatedCourses> {
        try {
            const res = (await this._httpBase.get('/course/available', {page, limit, defaultLanguage, categoryId}));
            return res.data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async createCourse(details: any, priceUSD: number, difficulty: string, categories: Array<string>): Promise<boolean> {
        try {
            const response = await this._httpBase.post('/course/create', {
                details,
                priceUSD: priceUSD ? Number(priceUSD) : 0,
                difficulty,
                categories
            });
            if (response)
                return response.data.success;
        } catch (error) {
            console.error(error)
            throw error;
        }
        return false;
    }

    async deleteCourse(courseId: string): Promise<boolean> {
        try {
            return (await this._httpBase.delete('courses/' + courseId)).data.success;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async getCourse(courseId: string, defaultLanguage: string): Promise<Course | undefined> {
        try {
            const response = await this._httpBase.get('/course/one', {courseId, defaultLanguage});
            console.log(response)
            if (response)
                return response.data.course;
        } catch (error) {
            console.error(error)
            throw error;
        }
        return undefined;
    }

    async updateCourse(courseId: string, course: Course): Promise<boolean> {
        try {
            return (await this._httpBase.put('courses/' + courseId, {course})).data.success;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async addModule(title: string, description: string, duration: string, courseId: string): Promise<boolean> {
        try {
            const response = await this._httpBase.post('course/create-module', {
                title,
                description,
                courseId,
                duration
            });
            if (response)
                return response.data.success;
        } catch (error) {
            console.error(error)
            throw error;
        }
        return false;
    }

    async addSection(title: string, description: string, duration: string, courseModuleId: string, file: File): Promise<boolean> {
        try {
            const formData: FormData = new FormData();
            formData.append('video', file, file.name);
            formData.append('title', title);
            formData.append('description', description);
            formData.append('courseModuleId', courseModuleId);
            formData.append('duration', duration);
            const response = await this._httpBase.post('course/create-section', formData);
            if (response)
                return response.data.success;
        } catch (error) {
            console.error(error)
            throw error;
        }
        return false;
    }
}

export {
    CoursesDatasource
}