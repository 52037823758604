import {createI18n} from "vue-i18n";
import en from "./en.json";
import es from "./es.json";

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'n',
  legacy: false,
  messages: {
    en,
    es
  },
  datetimeFormats: {
    'en': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    'es': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      }
    },
  }
});

export default i18n;
