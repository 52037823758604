// Sample lectures
import {Modules} from '@/core/domain/entities/module.entity';
import {Lectures} from '@/core/domain/entities/lecture.entity';

const lectures1: Lectures = [
  {
    lectureId: "L001",
    name: "Introduction to Programming Concepts",
    description: "Overview of basic programming concepts such as variables, data types, and control structures.",
    duration: 30*60000 // Duration in minutes*60000
  },
  {
    lectureId: "L002",
    name: "Variables and Data Types",
    description: "Understanding variables, data types, and their usage in programming.",
    duration: 45*60000
  },
  {
    lectureId: "L003",
    name: "Control Structures",
    description: "Introduction to if statements, loops, and other control structures in programming.",
    duration: 60*60000
  }
];

const lectures2: Lectures = [
  {
    lectureId: "L004",
    name: "Functions and Scope",
    description: "Understanding functions, parameters, and variable scope in programming.",
    duration: 60*60000
  },
  {
    lectureId: "L005",
    name: "Arrays and Loops",
    description: "Working with arrays and loops to process collections of data.",
    duration: 45*60000
  }
];

const lectures3: Lectures = [
  {
    lectureId: "L006",
    name: "Introduction to Object-Oriented Programming",
    description: "Understanding the principles of object-oriented programming (OOP).",
    duration: 60*60000
  },
  {
    lectureId: "L007",
    name: "Classes and Objects",
    description: "Introduction to classes, objects, and constructors.",
    duration: 45*60000
  },
  {
    lectureId: "L008",
    name: "Inheritance and Polymorphism",
    description: "Understanding inheritance and polymorphism in object-oriented programming.",
    duration: 60*60000
  }
];

const lectures4: Lectures = [
  {
    lectureId: "L009",
    name: "Introduction to Web Development",
    description: "Overview of web development technologies and concepts.",
    duration: 30*60000
  },
  {
    lectureId: "L010",
    name: "HTML Basics",
    description: "Introduction to HTML markup language.",
    duration: 45*60000
  },
  {
    lectureId: "L011",
    name: "CSS Fundamentals",
    description: "Basic styling and layout with CSS.",
    duration: 45*60000
  },
  {
    lectureId: "L012",
    name: "Introduction to JavaScript",
    description: "Fundamentals of JavaScript programming language.",
    duration: 60*60000
  }
];

// Sample modules
export const modules: Modules = [
  {
    moduleId: "M001",
    courseId: "ABC123",
    name: "Introduction to Programming",
    lectures: lectures1,
    expanded: false
  },
  {
    moduleId: "M002",
    courseId: "ABC123",
    name: "Advanced Programming Concepts",
    lectures: lectures2,
    expanded: false
  },
  {
    moduleId: "M003",
    courseId: "ABC123",
    name: "Object-Oriented Programming",
    lectures: lectures3,
    expanded: false
  },
  {
    moduleId: "M004",
    courseId: "ABC123",
    name: "Web Development Fundamentals",
    lectures: lectures4,
    expanded: false
  }
];
