import {Modules} from '@/core/domain/entities/module.entity';
import {Lecture} from '@/core/domain/entities/lecture.entity';
import {LectureInputModel} from '@/infrastructure/data/models/lectureInput.model';
import {HttpBase} from '@/infrastructure/di/httpBase';
import {ILecturesDataSource} from '@/core/domain/datasources/lectures.datasource';
import {modules} from '@/infrastructure/data/datasources/modules.mock';


export class LecturesDatasource implements ILecturesDataSource {
  private _httpBase: HttpBase;

  constructor() {
    this._httpBase = new HttpBase();
  }

  async getLectures(courseId: string): Promise<Modules> {
    try {
      return (await this._httpBase.get('/lectures', {courseId})).data.data;
    } catch (e) {
      return modules;
    }
  }

  async getLecture(lectureId: string): Promise<Lecture> {
    return (await this._httpBase.get('/lecture', {lectureId})).data.data;
  }

  async createLecture(body: LectureInputModel): Promise<boolean> {
    return (await this._httpBase.post('/lecture', {...body})).data.success;
  }

  async editLecture(lectureId: string, body: LectureInputModel): Promise<boolean> {
    return (await this._httpBase.put('/lecture', {lectureId, ...body})).data.success;
  }

  async deleteLecture(lectureId: string): Promise<boolean> {
    return (await this._httpBase.delete('/lecture')).data.success;
  }
}
