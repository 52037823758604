<template>
  <section class="course__area pt-120 pb-120">
    <div class="container">
      <div class="course__tab-inner grey-bg-2 mb-50">
        <div class="row align-items-center">
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div class="course__tab-wrapper d-flex align-items-center">
              <div class="course__tab-btn">
                <ul class="nav nav-tabs" id="courseTab" role="tablist">

                  <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{active: showCoursesAs === 'grid'}"
                            @click="() => changeDataShownAs('grid')" id="grid-tab" data-bs-toggle="tab"
                            data-bs-target="#grid"
                            type="button" role="tab" aria-controls="grid" :aria-selected="showCoursesAs === 'grid'">
                      <svg class="grid" viewBox="0 0 24 24">
                        <rect x="3" y="3" class="st0" width="7" height="7"/>
                        <rect x="14" y="3" class="st0" width="7" height="7"/>
                        <rect x="14" y="14" class="st0" width="7" height="7"/>
                        <rect x="3" y="14" class="st0" width="7" height="7"/>
                      </svg>
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button class="nav-link list" :class="{active: showCoursesAs === 'list'}"
                            @click="() => changeDataShownAs('list')" id="list-tab" data-bs-toggle="tab"
                            data-bs-target="#list"
                            type="button" role="tab" aria-controls="list" :aria-selected="showCoursesAs === 'list'">
                      <svg class="list" viewBox="0 0 512 512">
                        <g id="Layer_2_1_">
                          <path class="st0"
                                d="M448,69H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,69,448,69z"/>
                          <circle class="st0" cx="64" cy="100" r="31"/>
                          <path class="st0"
                                d="M448,225H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,225,448,225z"/>
                          <circle class="st0" cx="64" cy="256" r="31"/>
                          <path class="st0"
                                d="M448,381H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,381,448,381z"/>
                          <circle class="st0" cx="64" cy="412" r="31"/>
                        </g>
                      </svg>
                    </button>
                  </li>

                </ul>
              </div>
              <div class="course__view">
                <h4>{{$t('pagination.showing')}}
                  {{ `${courses.metadata.page === 1 ? 1 : ((20 * courses.metadata.page) + 1)} - ${courses.metadata.total < 20 ? courses.metadata.total : courses.metadata.page === courses.metadata.totalPages ? courses.metadata.total % 20 : (20 * courses.metadata.page)}  ${$t('pagination.of')} ${courses.metadata.total}` }}</h4>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div class="course__sort d-flex justify-content-sm-end">
              <div class="course__sort-inner">
                <select v-model="selectedCategory" @change="getCourses(1, $i18n.locale, selectedCategory.categoryId)">
                  <option :selected="true" :value="{}">{{$t('course.all')}}</option>
                  <option v-for="category in categories" :value="category"
                          :key="category.categoryId">{{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-12">
          <div class="course__tab-conent">
            <div class="tab-content" id="courseTabContent">
              <div class="tab-pane fade show active" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                <div class="row">
                  <!-- GridTabItems  -->
                  <GridTabItems :courses="courses.data"/>
                  <!-- GridTabItems  -->
                </div>
              </div>

              <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
                <div class="row">
                  <!-- GridTabItems  -->
                  <ListTabItems :courses="courses.data"/>
                  <!-- GridTabItems  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pagination start -->
      <Pagination :current-page="courses.metadata.page" :total-pages="courses.metadata.totalPages"
                  @update:page="page => getCourses(page, $i18n.locale)"/>
      <!-- pagination end -->

    </div>
  </section>
</template>

<script lang="ts">
import GridTabItems from './GridTabItems.vue';
import ListTabItems from './ListTabItems.vue';
import Pagination from '../common/Pagination.vue';
import {defineComponent} from 'vue';
import {CoursesUseCase} from '@/core/usecases/courses.usecase';
import {mapGetters, useStore} from 'vuex';
import {key} from '@/infrastructure/di/store';
import {CategoriesUseCase} from '@/core/usecases/categories.usecase';
import {Category} from '@/core/domain/entities/category.entity';

export default defineComponent({
  name: 'CoursesArea',
  components: {
    Pagination,
    GridTabItems,
    ListTabItems,
  },
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  data() {
    return {
      selectedCategory: {} as Category
    }
  },
  methods: {
    async getCourses(page: number, language: string, category?: string) {
      const coursesUseCase = new CoursesUseCase(this.store);
      await coursesUseCase.getCourses(page, 20, language, category);
    },
    async getCategories(language: string) {
      const categoriesUseCase = new CategoriesUseCase(this.store);
      await categoriesUseCase.getCategories(language);
    },
    changeDataShownAs(value: 'grid' | 'list') {
      this.store.dispatch('setShowCoursesAs', value);
    },
    // selectCategory(category: Category) {
    //   this.selectedCategory = category;
    //   this.getCourses(1, 'es', this.selectedCategory.categoryId);
    // }
  },
  computed: {
    ...mapGetters(['courses']),
    ...mapGetters(['showCoursesAs']),
    ...mapGetters(['categories'])
  },
  mounted() {
    this.getCourses(1, this.$i18n.locale);
    this.getCategories(this.$i18n.locale);
  }
});
</script>

