import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c60d0e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider__area p-relative mt-60" }
const _hoisted_2 = { class: "slider__wrapper swiper-container" }
const _hoisted_3 = { class: "content__info" }
const _hoisted_4 = { class: "info__description" }
const _hoisted_5 = { class: "info__actions" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_swiper, {
        ref: "mySwiper",
        modules: _ctx.swiperModules,
        effect: 'fade',
        loop: true,
        autoplay: {delay: 5000}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coursesToShow, (course, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "hero__content content",
                  style: _normalizeStyle({background: `linear-gradient(to right, ${course.backgroundFrom}, ${course.backgroundTo})`})
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", {
                      class: "info__title",
                      style: _normalizeStyle({color: `${course.textColor}`})
                    }, [
                      _createElementVNode("span", null, _toDisplayString(course.title), 1)
                    ], 4),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", {
                        class: "desc__text",
                        style: _normalizeStyle({color: `${course.textColor}`})
                      }, _toDisplayString(course.body), 5)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("button", {
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToRegister && _ctx.goToRegister(...args))),
                        class: "acts__start",
                        style: _normalizeStyle({background: `${course.buttonColor}`, color: `${course.textButtonColor}`})
                      }, _toDisplayString(_ctx.$t('home.stCourse')), 5),
                      _createElementVNode("button", {
                        class: "acts__all",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToCourses && _ctx.goToCourses(...args))),
                        style: _normalizeStyle({background: `transparent`, color: `${course.textColor}`, border:`1px solid ${course.textColor}` })
                      }, _toDisplayString(_ctx.$t('actions.seeAll')), 5)
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: "content__image",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToRegister && _ctx.goToRegister(...args)))
                  }, [
                    _createElementVNode("img", {
                      src: course.image,
                      alt: ""
                    }, null, 8, _hoisted_6)
                  ])
                ], 4)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules"])
    ])
  ]))
}