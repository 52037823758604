import {CoursesDatasource} from '../datasources/courses.datasource.impl';
import {Course, PaginatedCourses} from '@/core/domain/entities/course.entity';
import {ICoursesDataSource} from '@/core/domain/datasources/courses.datasource';
import {ICoursesRepository} from '@/core/domain/repositories/courses.repository';

class CoursesRepository implements ICoursesRepository {
    private _datasSource: ICoursesDataSource;

    constructor() {
        this._datasSource = new CoursesDatasource()
    }

    async getCourse(courseId: string, language: string): Promise<Course | undefined> {
        return await this._datasSource.getCourse(courseId, language);
    }

    async getCourses(page: number, limit: number, language: string, category?: string): Promise<PaginatedCourses> {
        return await this._datasSource.getCourses(page, limit, language, category);
    }

    async createCourse(details: any, priceUSD: number, difficulty: string, categories: Array<string>): Promise<any> {
        return await this._datasSource.createCourse(details, priceUSD, difficulty, categories);
    }

    async addModule(title: string, description: string, duration: string, courseId: string): Promise<boolean> {
        return await this._datasSource.addModule(title, description, duration, courseId);
    }

    async addSection(title: string, description: string, duration: string, courseModuleId: string, file: File): Promise<boolean> {
        return await this._datasSource.addSection(title, description, duration, courseModuleId, file);
    }


}

export {
    CoursesRepository
}
