import {CategoriesDatasource} from '../datasources/categories.datasource.impl';
import {Categories} from '@/core/domain/entities/category.entity';
import {ICategoryDatasource} from '@/core/domain/datasources/category.datasource';
import {ICategoriesRepository} from '@/core/domain/repositories/categories.repository';

class CategoriesRepository implements ICategoriesRepository{
  private _dataSource: ICategoryDatasource;

  constructor() {
    this._dataSource = new CategoriesDatasource();
  }

  async getCategories(language: string): Promise<Categories> {
    return await this._dataSource.getCategories(language);
  }
}

export  {
  CategoriesRepository
}
